import ReactDOM from 'react-dom/client';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import 'utils/yup';
import 'utils/i18n';
import 'utils/firebase';
import 'utils/firestore';
import 'utils/detectModernImageFormatsSupport';

import { BODY_EMBEDDED_CLASSNAME, DEFAULT_ROOT_ELEMENT_ID } from 'constants/common';
import { IS_EMBEDDED } from 'constants/common';

import observeElementRemoval from 'utils/observeElementRemoval';
import getRootElement from 'utils/getRootElement';

import AppRouter from 'components/AppRouter';
import GlobalStateProvider from 'components/GlobalStateProvider';
import S3Provider from 'components/S3Provider';

import 'styles/index.scss';

const rootElementId = document.currentScript?.dataset.containerId || DEFAULT_ROOT_ELEMENT_ID;
const rootElement = getRootElement(rootElementId);
rootElement.setAttribute('lang', 'de');

if (IS_EMBEDDED) {
  document.documentElement.classList.add(BODY_EMBEDDED_CLASSNAME);
} else {
  rootElement.style.minHeight = 'inherit';
}

const root = ReactDOM.createRoot(rootElement as HTMLElement);

root.render(
  <GlobalStateProvider>
    <S3Provider>
      <AppRouter />
    </S3Provider>
  </GlobalStateProvider>
);

/*
  Observe the removal of the app's root element and unmount the entire component tree when it happens.
  This ensures proper cleanup of app components when the root element is removed from the host website in the embedded mode
*/
observeElementRemoval(`#${rootElement.id}`, () => {
  root.unmount();
});
