import { toast } from 'react-toastify';

import i18n from 'utils/i18n';

const defaultAutoCloseDelayInMs = 3_000;

const defaultParams = {
  position: 'top-right' as const,
  autoClose: defaultAutoCloseDelayInMs,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

/**
 * Displays a notification toast
 * @param message {string} message to display
 * @param autoCloseDelay {number} in miliseconds
 */
export const showSuccessfulNotification = (message: string, autoCloseDelay?: number): void => {
  toast.success(message, { ...defaultParams, autoClose: autoCloseDelay });
};

export const showWarningNotification = (message: string, autoCloseDelay?: number): void => {
  toast.warning(message, { ...defaultParams, autoClose: autoCloseDelay });
};

export const showErrorNotification = (message: string, autoCloseDelay?: number): void => {
  toast.error(message, { ...defaultParams, autoClose: autoCloseDelay });
};

export const showDefaultErrorNotification = () => {
  showErrorNotification(i18n.t('defaultErrorMessage'));
};
