import cn from 'classnames';

import style from './Spinner.module.scss';

interface IProps {
  className?: string;
}

const Spinner = ({ className }: IProps) => {
  return <div className={cn(style.spinner, className)}></div>;
};

export default Spinner;
