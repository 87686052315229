export const wiggleElement = (selector: string) => {
  const animationDuration = 150;
  const animationIterationCount = 5;
  const animationClassName = 'wiggle-animation';
  let timeoutId;

  const animatedElement = document.querySelector<HTMLElement>(selector);

  if (animatedElement && !timeoutId) {
    animatedElement.style.animationDuration = `${animationDuration}ms`;
    animatedElement.style.animationIterationCount = `${animationIterationCount}`;
    animatedElement.classList.add(animationClassName);

    timeoutId = setTimeout(() => {
      animatedElement.classList.remove(animationClassName);
      timeoutId = null;
    }, animationDuration * animationIterationCount);
  }
};
