import { ReactNode, useCallback } from 'react';
import { useSetState } from 'react-use';
import mergeWith from 'lodash/mergeWith';
import isFunction from 'lodash/isFunction';

import GlobalStateContext, { TGlobalStatePatchGetter } from 'utils/GlobalStateContext';
import { IInitialState, initialState } from 'utils/initialState';

interface IProps {
  children: ReactNode;
}

const GlobalStateProvider = (props: IProps) => {
  const { children } = props;

  const [state, setState] = useSetState(initialState);

  const setGlobalState = useCallback(
    (patchOrPatchGetter: Partial<IInitialState> | TGlobalStatePatchGetter) => {
      setState(prevState => {
        let patch;

        if (isFunction(patchOrPatchGetter)) {
          patch = patchOrPatchGetter(prevState);
        } else {
          patch = patchOrPatchGetter;
        }

        const newState = mergeWith(prevState, patch, (a: never, b: any) => {
          return Array.isArray(b) ? b : undefined;
        });

        return newState;
      });
    },
    [setState]
  );

  return (
    <GlobalStateContext.Provider value={[state, setGlobalState]}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
