import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import cn from 'classnames';

import { TBillingPeriod, billingPeriodOptions } from 'types/interfaces';

import styles from './BillingPeriodSwitcher.module.scss';

interface IProps {
  className?: string;
  onChange?: (billingPeriod: TBillingPeriod) => void;
  value?: TBillingPeriod;
}

const BillingPeriodSwitcher: React.FC<PropsWithChildren<IProps>> = props => {
  const { className, onChange, value } = props;

  const { t } = useTranslation();

  const handleOptionClick = (billingPeriod: TBillingPeriod) => () => {
    onChange?.(billingPeriod);
  };

  return (
    <>
      <div className={cn(className, styles.containerOuter)}>
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <div
              className={cn(styles.switch, {
                [styles.switchAlternated]: value === 'year',
              })}
            ></div>

            {billingPeriodOptions.map(billingPeriodOption => (
              <div
                key={billingPeriodOption}
                className={cn(styles.option, styles[`option${capitalize(billingPeriodOption)}ly`], {
                  [styles.optionSelected]: value === billingPeriodOption,
                })}
                onClick={handleOptionClick(billingPeriodOption)}
              >
                {t(`${billingPeriodOption}ly`)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingPeriodSwitcher;
