import { and, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { CompleteMultipartUploadCommandOutput } from '@aws-sdk/client-s3';
import { filesize } from 'filesize';
import { Upload } from '@aws-sdk/lib-storage';
import { useMount } from 'react-use';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import cn from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import uniqueId from 'lodash/uniqueId';

import Button from 'components/Button';
import Dropzone from 'components/Dropzone';
import Modal from 'components/Modal';
import PageProgressBar from 'components/PageProgressBar';
import Spinner from 'components/Spinner';

import { ReactComponent as IconUpload } from 'images/icons/cloudUpload.svg';
import { ReactComponent as IconFile } from 'images/icons/fileBlank.svg';
import { ReactComponent as IconReload } from 'images/icons/reload.svg';

import useS3 from 'hooks/useS3';
import useGlobalState from 'hooks/useGlobalState';
import useUpgradePlan from 'hooks/useUpgradePlan';
import useSubscription from 'hooks/useSubscription';

import { AWS_BUCKET_NAME, MAX_FILES_PER_UPLOAD } from 'constants/common';

import { getFirebaseLikeTimestamp } from 'utils/date';
import { db, filesCollection } from 'utils/firestore';
import { getS3ObjectCloudFrontUrl } from 'utils/aws';
import {
  showDefaultErrorNotification,
  showErrorNotification,
  showSuccessfulNotification,
} from 'utils/notifications';

import { IFile, truthy } from 'types/interfaces';
import {
  IUploadedFile,
  TSaveFileReferenceToFirestore,
  TUploadFileToS3,
  TUploadStatus,
} from './types';

import styles from './UploadFilesModal.module.scss';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  defaultFiles?: File[];
}

const getId = () => uniqueId('file_');
const getDefaultUploadedFile = (file: File) => ({
  file,
  id: getId(),
  uploadStatus: 'new' as const,
  progressPercentage: null,
  error: null,
});

const ALREADY_UPLOADED_ERROR = 'This file has already been uploaded here';
const NOT_ENOUGH_STORAGE_ERROR =
  "You don't have enough available storage space to upload this file";

const UploadFilesModal = (props: IProps) => {
  const { isOpen, onRequestClose, defaultFiles = [] } = props;

  const { s3Client } = useS3();
  const { t } = useTranslation();

  const [globalState, setGlobalState] = useGlobalState();
  const { profile, hashtagData, currentUser, folderReference } = globalState;

  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>(
    defaultFiles.map(getDefaultUploadedFile)
  );
  const [isUploadingAll, toggleUploadingAllState] = useState(false);

  const { upgradePlanModal, openUpgradeModal } = useUpgradePlan();
  const { refreshSubscriptionData } = useSubscription();

  useMount(() => {
    refreshSubscriptionData(profile);
  });

  const handleDropAccepted = (acceptedFiles: File[]) => {
    const newFiles = acceptedFiles
      .filter(acceptedFile => {
        return uploadedFiles.every(uploadedFile => {
          const file = uploadedFile.file;

          const isDifferentName = acceptedFile.name !== file.name;
          const isDifferentSize = acceptedFile.size !== file.size;

          const isNewFile = isDifferentName || isDifferentSize;

          return isNewFile;
        });
      })
      .map(getDefaultUploadedFile);

    setUploadedFiles(prevFiles => [...newFiles, ...prevFiles]);
  };

  const handleFileRemove = (removedFile: IUploadedFile) => {
    setUploadedFiles(prevFiles =>
      prevFiles.filter(prevFile => {
        return prevFile.id !== removedFile.id;
      })
    );
  };

  const handleUploadedFileChange = (changedFields: { id: string } & Partial<IUploadedFile>) => {
    setUploadedFiles(prevFiles => {
      const indexToUpdate = prevFiles.findIndex(prevFile => prevFile.id === changedFields.id);

      const newFiles = cloneDeep(prevFiles);
      const prevFile = prevFiles[indexToUpdate];

      newFiles[indexToUpdate] = { ...prevFile, ...changedFields };

      return newFiles;
    });
  };

  const clearFiles = () => {
    setUploadedFiles([]);
  };

  const handleRequestClose = () => {
    clearFiles();
    onRequestClose?.();
  };

  const updateFileUploadProgress = (uploadedFile: IUploadedFile, progressPercentage: number) => {
    handleUploadedFileChange({ id: uploadedFile.id, progressPercentage });
  };

  const updateFileUploadStatus = (uploadedFile: IUploadedFile, uploadStatus: TUploadStatus) => {
    handleUploadedFileChange({
      id: uploadedFile.id,
      uploadStatus,
      ...(uploadStatus === 'uploading' && { error: null }),
      ...(['uploading', 'error'].includes(uploadStatus) && { progressPercentage: 0 }),
    });
  };

  const uploadFileToS3: TUploadFileToS3 = async ({ client, uploadedFile, Key }) => {
    const params = {
      Bucket: AWS_BUCKET_NAME,
      Body: uploadedFile.file,
      Key,
      ContentType: uploadedFile.file.type,
    };

    const upload = new Upload({
      client,
      params,
    });

    upload.on('httpUploadProgress', progress => {
      if (progress.loaded && progress.total) {
        const progressPercentage = Math.round((progress.loaded * 100) / progress.total);

        updateFileUploadProgress(uploadedFile, progressPercentage);
      }
    });

    return upload.done();
  };

  const saveFileReferenceToFirestore: TSaveFileReferenceToFirestore = async ({
    hashtagData,
    sizeInMB,
    ownerUid,
    type,
    name,
    url,
  }) => {
    const uploadedFileDocRef = doc(filesCollection);
    const fileUid = uploadedFileDocRef.id;

    const uploadedFileDocData = {
      createdAt: getFirebaseLikeTimestamp(),
      folderReference: folderReference || '',
      hashtagName: hashtagData.name,
      hashtagUid: hashtagData.uid,
      name,
      ownerUid: ownerUid,
      sizeInMB,
      type,
      url,
      username: profile?.name.customUsername,
      uid: fileUid,
    };

    await setDoc(uploadedFileDocRef, { ...uploadedFileDocData });

    setGlobalState(prevState => {
      const prevFiles = cloneDeep(prevState.files) || [];

      const newFiles = [uploadedFileDocData, ...prevFiles];

      return { files: newFiles };
    });
  };

  const getFileNameComponents = (file: File) => {
    const lastIndexOfDot = file.name.lastIndexOf('.');

    const fileName = file.name.slice(0, lastIndexOfDot);
    const fileExtensionWithoutDot = file.name.slice(lastIndexOfDot + 1);

    const fileType = fileExtensionWithoutDot || file.type.split('/')[1];

    return [fileName, fileType];
  };

  const checkFileNameAvailability = async (fileName: string, hashtagUid: string) => {
    const foldersQuery = query(
      filesCollection,
      and(
        where('hashtagUid', '==', hashtagUid),
        where('folderReference', '==', folderReference || ''),
        where('name', '==', fileName)
      )
    );
    const filesQuerySnapshot = await getDocs(foldersQuery);
    const files = filesQuerySnapshot.docs.map(folder => folder.data() as IFile);

    return files.length === 0;
  };

  const checkStorageAvailability = (sizeInMB: number) => {
    if (!profile || !profile.planStorageInMB) return false;

    return profile.usedStorageInMB + sizeInMB <= profile.planStorageInMB;
  };

  const updateUsedStorage = async (sizeInMB: number) => {
    if (!profile) return;

    const profileData = {
      ...profile,
      usedStorageInMB: profile?.usedStorageInMB + sizeInMB,
    };

    setGlobalState({ profile: profileData });

    return updateDoc(doc(db, 'profiles', profile?.uid), {
      usedStorageInMB: profileData.usedStorageInMB,
    });
  };

  const handleFileUploadClick =
    (uploadedFile: IUploadedFile, showNotification: boolean = true) =>
    async () => {
      try {
        if (!s3Client || !hashtagData || !currentUser) {
          showErrorNotification(t('failedConnectToCloud'));

          return;
        }

        updateFileUploadStatus(uploadedFile, 'uploading');

        const file = uploadedFile.file;
        const [fileNameWithoutExt, fileExtension] = getFileNameComponents(file);

        const canUploadToFolder = await checkFileNameAvailability(
          fileNameWithoutExt,
          hashtagData.uid
        );

        if (!canUploadToFolder) {
          throw new Error(ALREADY_UPLOADED_ERROR);
        }

        const fileSizeInMB = Math.ceil(file.size / 1000 / 1000);
        const haveEnoughStorageRemaining = checkStorageAvailability(fileSizeInMB);

        if (!haveEnoughStorageRemaining) {
          throw new Error(NOT_ENOUGH_STORAGE_ERROR);
        }

        const Key = `${currentUser.uid}/${hashtagData.name}/${folderReference || ''}/${file.name}`;

        const uploadResult = await uploadFileToS3({ uploadedFile, Key, client: s3Client });
        const uploadedObjectUrl = getS3ObjectCloudFrontUrl(Key);

        await saveFileReferenceToFirestore({
          name: fileNameWithoutExt,
          type: fileExtension,
          hashtagData,
          url: uploadedObjectUrl,
          sizeInMB: fileSizeInMB,
          ownerUid: currentUser.uid,
        });
        await updateUsedStorage(fileSizeInMB);

        updateFileUploadStatus(uploadedFile, 'uploaded');

        if (showNotification) {
          showSuccessfulNotification(t('fileUploaded'));
        }

        return uploadResult;
      } catch (error) {
        handleUploadedFileChange({
          id: uploadedFile.id,
          progressPercentage: 0,
          error: error.message,
          uploadStatus: 'error',
        });

        const isKnownError = [NOT_ENOUGH_STORAGE_ERROR, ALREADY_UPLOADED_ERROR].includes(
          error.message
        );

        if (showNotification && !isKnownError) {
          showDefaultErrorNotification();
        }

        if (error.message === NOT_ENOUGH_STORAGE_ERROR) {
          openUpgradeModal();
        }

        return null;
      }
    };

  const getRemainingFiles = () => {
    const remainingFiles = uploadedFiles.filter(uploadedFile => {
      const doesntHaveBlockingError =
        uploadedFile.error !== ALREADY_UPLOADED_ERROR &&
        uploadedFile.error !== NOT_ENOUGH_STORAGE_ERROR;

      const hasStatusSuitableForUpload = ['new', 'error'].includes(uploadedFile.uploadStatus);

      return hasStatusSuitableForUpload && doesntHaveBlockingError;
    });

    return remainingFiles;
  };

  const getTotalUploadProgress = (uploadingFiles: IUploadedFile[]) => {
    return uploadingFiles.reduce((totalProgressInBytes, uploadingFile) => {
      const uploadProgressInPercents = uploadingFile.progressPercentage || 0;
      const uploadProgessInBytes = (uploadingFile.file.size * uploadProgressInPercents) / 100;

      return totalProgressInBytes + uploadProgessInBytes;
    }, 0);
  };

  const getTotalFilesSize = (uploadingFiles: IUploadedFile[]) => {
    return uploadingFiles.reduce(
      (totalFilesSizeInBytes, uploadingFile) => totalFilesSizeInBytes + uploadingFile.file.size,
      0
    );
  };

  const getTotalUploadProgressInPercentage = () => {
    const calculatedFiles = uploadedFiles.filter(file => file.uploadStatus !== 'error');

    const totalFilesSizeInBytes = getTotalFilesSize(calculatedFiles);
    const uploadProgressInBytes = getTotalUploadProgress(calculatedFiles);

    const totalProgressPercentage = Math.round(
      (uploadProgressInBytes * 100) / totalFilesSizeInBytes
    );

    return totalProgressPercentage;
  };

  type TUploadResults = Array<CompleteMultipartUploadCommandOutput | null | undefined>;
  const handleUploadAllClick = async () => {
    toggleUploadingAllState(true);

    const initialValue: Promise<TUploadResults> = Promise.resolve([]);
    const filesToBeUploaded = getRemainingFiles();

    const uploadResults = await filesToBeUploaded.reduce(
      async (uploadResultsPromise, uploadedFile) => {
        const uploadResults = await uploadResultsPromise;

        const uploadFile = handleFileUploadClick(uploadedFile, false);

        const uploadResult = await uploadFile();

        return [...uploadResults, uploadResult];
      },
      initialValue
    );

    const successfullUploads = uploadResults.filter(truthy);
    const allFilesWereSuccesfullyUploaded = successfullUploads.length === uploadResults.length;

    if (successfullUploads.length === 1) {
      showSuccessfulNotification(t('fileUploaded'));
    }

    if (successfullUploads.length > 1) {
      showSuccessfulNotification(t('filesUploaded'));
    }

    if (allFilesWereSuccesfullyUploaded) {
      handleRequestClose();
    }

    toggleUploadingAllState(false);
  };

  const isSomethingUploading = uploadedFiles.some(
    uploadedFile => uploadedFile.uploadStatus === 'uploading'
  );

  const remainingFiles = getRemainingFiles();
  const totalProgressPercentage = getTotalUploadProgressInPercentage();

  return (
    <>
      <PageProgressBar progress={totalProgressPercentage} isVisible={isUploadingAll} />

      <Modal
        isOpen={isOpen}
        onRequestClose={isSomethingUploading ? () => {} : handleRequestClose}
        contentClassName={styles.modalContent}
        overlayClassName={styles.overlay}
        className={styles.modal}
      >
        <Dropzone className={styles.dropzone} onDropAccepted={handleDropAccepted} />

        {uploadedFiles.length > 0 && (
          <>
            <h3 className={styles.title}>
              <span className={styles.text}>{t('fileUploadModalSelectedFiles')}</span>
              <span className={styles.smallText}>
                ({uploadedFiles.length}
                <span className={styles.separator}>/</span>
                {MAX_FILES_PER_UPLOAD})
              </span>
            </h3>

            <PerfectScrollbar className={styles.filesContainer}>
              {uploadedFiles.map(uploadedFile => {
                const isUploading = uploadedFile.uploadStatus === 'uploading';
                const isUploaded = uploadedFile.uploadStatus === 'uploaded';
                const isNew = uploadedFile.uploadStatus === 'new';

                const alreadyUploaded = uploadedFile.error === ALREADY_UPLOADED_ERROR;
                const notEnoughStorage = uploadedFile.error === NOT_ENOUGH_STORAGE_ERROR;

                const hasError = uploadedFile.uploadStatus === 'error';
                const hasRecoverableError = hasError && !alreadyUploaded && !notEnoughStorage;

                return (
                  <div
                    className={cn(styles.file, {
                      [styles.failedFile]: hasError,
                    })}
                    key={uploadedFile.id}
                  >
                    <Button
                      onClick={() => handleFileRemove(uploadedFile)}
                      type="secondary"
                      className={cn(styles.fileAction, styles.fileActionRemove)}
                    >
                      ×
                    </Button>

                    <div className={styles.left}>
                      <IconFile className={styles.fileIcon}></IconFile>
                    </div>

                    <div className={styles.middle}>
                      <div className={styles.filename}>{uploadedFile.file.name}</div>
                      <div className={styles.fileInfo}>
                        <div className={styles.fileSize}>{filesize(uploadedFile.file.size)}</div>
                      </div>
                      {uploadedFile.error && (
                        <div className={styles.error}>{uploadedFile.error}</div>
                      )}

                      <div
                        className={cn(styles.progressContainer, {
                          [styles.progressContainerVisible]: isUploading,
                        })}
                      >
                        <div
                          className={cn(styles.progressBar, {
                            [styles.progressBarUploading]:
                              isUploading && uploadedFile.progressPercentage,
                          })}
                          style={{ width: `${uploadedFile.progressPercentage || 0}%` }}
                        ></div>
                      </div>
                    </div>

                    {isUploaded && (
                      <div className={styles.fileActionContainer}>
                        <div className={styles.check}></div>
                      </div>
                    )}

                    {isUploading && (
                      <div className={styles.fileActionContainer}>
                        <Spinner></Spinner>
                      </div>
                    )}

                    {(isNew || hasRecoverableError) && (
                      <Button
                        type="primary"
                        className={cn(styles.fileAction, styles.fileActionUpload)}
                        onClick={handleFileUploadClick(uploadedFile)}
                        disabled={isUploadingAll}
                      >
                        {hasRecoverableError ? (
                          <IconReload
                            className={cn(styles.fileActionIcon, styles.fileActionIconReload)}
                          ></IconReload>
                        ) : (
                          <IconUpload className={styles.fileActionIcon} />
                        )}
                      </Button>
                    )}
                  </div>
                );
              })}
            </PerfectScrollbar>

            <div className={styles.buttons}>
              <Button
                className={cn(styles.button, styles.cancelButton)}
                type="secondary"
                onClick={clearFiles}
                disabled={isSomethingUploading || isUploadingAll}
              >
                {t('fileUploadModalClearFiles')}
              </Button>

              {remainingFiles.length > 0 || isSomethingUploading ? (
                <Button
                  className={styles.button}
                  type="primary"
                  iconClassName={styles.uploadAllIcon}
                  iconLeft={isUploadingAll ? null : <IconUpload />}
                  disabled={isUploadingAll}
                  onClick={handleUploadAllClick}
                  isLoading={isUploadingAll}
                >
                  {t('uploadfiles')}
                </Button>
              ) : (
                <Button
                  className={styles.button}
                  type="primary"
                  disabled={isSomethingUploading || isUploadingAll}
                  onClick={handleRequestClose}
                >
                  {t('close')}
                </Button>
              )}
            </div>
          </>
        )}
      </Modal>

      {upgradePlanModal}
    </>
  );
};

export default UploadFilesModal;
