import { MouseEvent, useRef } from 'react';
import { useClickAway, useToggle, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as IconPlus } from 'images/icons/plus.svg';
import { ReactComponent as IconFiles } from 'images/icons/files.svg';
import { ReactComponent as IconFolder } from 'images/icons/folder2.svg';

import styles from './UploadFileToggle.module.scss';
import useAuth from 'hooks/useAuth';
import useGlobalState from 'hooks/useGlobalState';

export type TUploadOption = 'folder' | 'files';

interface IProps {
  onClick?: () => void;
  onOptionClick?: (option: TUploadOption) => void;
  isModalOpened: boolean;
}

const UploadFileToggle = (props: IProps) => {
  const { t } = useTranslation();

  const { onOptionClick, isModalOpened } = props;

  const { showAuthModal } = useAuth();
  const [globalState] = useGlobalState();
  const { isAuthorized } = globalState;

  const [isActive, toggleActiveState] = useToggle(false);
  const [isMenuOpened, toggleMenuState] = useToggle(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useUpdateEffect(() => {
    if (!isModalOpened && isActive) {
      toggleActiveState(false);
    }
  }, [isModalOpened]);

  const handleContainerClick = () => {
    toggleActiveState();

    if (isAuthorized) {
      toggleMenuState();
    } else {
      showAuthModal();
    }
  };

  const handleOptionClick = (option: TUploadOption) => (event: MouseEvent) => {
    event.stopPropagation();

    onOptionClick?.(option);
    toggleMenuState(false);
  };

  useClickAway(containerRef, event => {
    const target = event?.target;

    const isModalClick = Boolean((target as HTMLElement)?.closest('.ReactModalPortal'));

    if (!isModalClick) {
      toggleActiveState(false);
    }

    toggleMenuState(false);
  });

  return (
    <>
      <div
        ref={containerRef}
        className={cn(styles.container, { [styles.active]: isActive })}
        onClick={handleContainerClick}
      >
        <div className={styles.iconContainer}>
          <IconPlus className={styles.icon}></IconPlus>
        </div>

        <div className={cn(styles.menu, { [styles.menuOpened]: isMenuOpened })}>
          <div className={styles.menuItem} onClick={handleOptionClick('folder')}>
            <IconFolder className={styles.menuItemIcon}></IconFolder>
            <div className={styles.menuItemText}>{t('addFolder')}</div>
          </div>
          <div className={styles.menuItem} onClick={handleOptionClick('files')}>
            <IconFiles className={styles.menuItemIcon}></IconFiles>
            <div className={styles.menuItemText}>{t('addFiles')}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFileToggle;
