import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as IconApple } from 'images/icons/apple.svg';

import useAuth from 'hooks/useAuth';

import styles from './SignInWithAppleButton.module.scss';

interface IProps {
  className?: string;
  children?: ReactNode;
}

const SignInWithAppleButton = (props: IProps) => {
  const { t } = useTranslation();

  const { className, children = t('signInWithApple') } = props;

  const { signInWithApple, isAuthorized } = useAuth();

  return isAuthorized ? null : (
    <>
      <button className={cn(styles.btn, className)} onClick={signInWithApple}>
        <IconApple className={styles.icon} />

        <span className={styles.text}>{children}</span>
      </button>
    </>
  );
};

export default SignInWithAppleButton;
