import { useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';

interface IWithAdditionalProps {
  isRemount: boolean;
  isInitialMount: boolean;
}

const withRemountOnParamChange =
  <P extends object>(WrappedComponent: React.FC<P & IWithAdditionalProps>) =>
  (props: P) => {
    const params = useParams();
    const prevParams = usePrevious(params);

    const getKey = (paramsObj: Object) => Object.values(paramsObj).join('.');

    const key = getKey(params);
    const prevKey = prevParams ? getKey(prevParams) : '';

    return (
      <WrappedComponent
        {...props}
        key={key}
        isRemount={Boolean(prevKey)}
        isInitialMount={!prevKey}
      />
    );
  };

export default withRemountOnParamChange;
