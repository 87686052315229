import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PricingFaq.module.scss';

interface IProps {}

const PricingFaq: React.FC<PropsWithChildren<IProps>> = _props => {
  const { t } = useTranslation();

  const faq = [
    {
      question: t('pricingFaqQuestion1'),
      answer: t('pricingFaqAnswer1'),
    },
    {
      question: t('pricingFaqQuestion2'),
      answer: t('pricingFaqAnswer2'),
    },
    {
      question: t('pricingFaqQuestion3'),
      answer: t('pricingFaqAnswer3'),
    },
  ];
  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.title}>{t('pricingFaqTitle')}</h2>

        {faq.length > 0 && (
          <div className={styles.faq}>
            {faq.map((faqEntry, faqEntryIndex) => (
              <div className={styles.faqEntry} key={faqEntryIndex}>
                <div className={styles.faqQuestion}>{faqEntry.question}</div>
                <div className={styles.faqAnswer}>{faqEntry.answer}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default PricingFaq;
