import { ChangeEvent, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { compareDesc, format } from 'date-fns';
import { isMobile } from 'react-device-detect';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { useToggle, useUnmount } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import dropRight from 'lodash/dropRight';
import groupBy from 'lodash/groupBy';
import InfiniteScroll from 'react-infinite-scroll-component';
import last from 'lodash/last';
import SelectionArea from '@viselect/react';
import { Helmet } from 'react-helmet';

import { ReactComponent as IconArrowLeft } from 'images/icons/arrowLeft.svg';
import { ReactComponent as IconSearch } from 'images/icons/search.svg';

import { TUploadOption } from 'components/UploadFileToggle/UploadFileToggle';
import Breadcrumbs from 'components/Breadcrumbs';
import CreateNewFolderModal from 'components/CreateNewFolderModal';
import File from 'components/File';
import FileActionsBar from 'components/FileActionsBar';
import HashtagPasswordProtection from 'components/HashtagPasswordProtection';
import HashtagReport from 'components/HashtagReport';
import Input from 'components/Input';
import PageDropzone from 'components/PageDropzone';
import PasswordProtectionModal from 'components/PasswordProtectionModal';
import Spinner from 'components/Spinner';
import UploadFilesModal from 'components/UploadFilesModal';
import UploadFileToggle from 'components/UploadFileToggle';

import { useProfile } from 'hooks/useProfile';
import useDragSelect from 'hooks/useDragSelect';
import useFetchFiles from 'hooks/useFetchFiles';
import useHandleDrop from 'hooks/useHandleDrop';

import { IFile, Nullable } from 'types/interfaces';

import { concatPaths } from 'utils/urls';
import { parseDate } from 'utils/date';
import GlobalStateContext from 'utils/GlobalStateContext';
import sha256 from 'utils/sha256';

import { FILES_PER_FETCH_SIZE } from 'constants/common';

import withRemountOnParamChange from 'HOCs/withRemountOnParamChange';

import styles from './SearchResultsPage.module.scss';
import fileStyles from 'components/File/File.module.scss';

interface IProps {
  isInitialMount: boolean;
  isRemount: boolean;
}

const SearchResultsPage = (props: IProps) => {
  const { isRemount } = props;
  const { hashtag, '*': splat } = useParams();

  const {
    fetchFiles,
    fetchNextFiles,
    files,
    hashtagData,
    isLoading: areFilesLoading,
    isLoadingMore: areMoreFilesLoading,
    clearFiles,
    hasMore,
  } = useFetchFiles();
  const { addHashtagToProfile } = useProfile();
  const hasAddedHashtagToProfileRef = useRef(false);
  const { selected, onMove, onStart, onBeforeStart } = useDragSelect();

  const alreadyFetchedFiles = Array.isArray(files);

  const [filterValue, setFilterValue] = useState('');
  const [canViewFiles, setCanViewFiles] = useState(true);
  const [defaultUploadingFiles, setDefaultUploadingFiles] = useState<File[]>([]);

  const [isUploadModalOpened, toggleUploadModalOpenedState] = useToggle(false);
  const [isNewFolderModalOpened, toggleNewFolderModalOpenedState] = useToggle(false);
  const [initialPageLoadingState, toggleInitialPageLoadingState] = useToggle(
    isRemount || !alreadyFetchedFiles
  );

  const onDropAccepted = (files: File[]) => {
    setDefaultUploadingFiles(prevFiles => [...files, ...prevFiles]);
    toggleUploadModalOpenedState();
  };

  const handleDropAccepted = useHandleDrop(onDropAccepted);

  const [globalState, setGlobalState] = useContext(GlobalStateContext);
  const { cachedPasswords, folderReference, isAuthorized, profile } = globalState;

  const folderNames = splat?.split('/').filter(Boolean);
  const currentFolderName = last(folderNames);
  const previousFolderLink = `/${concatPaths(hashtag, ...dropRight(folderNames, 1))}`;

  const filteredFiles =
    files?.filter(file => {
      const fileName = file.name.toLowerCase();
      const searchString = filterValue.toLowerCase();

      return fileName.includes(searchString);
    }) || [];

  const noFilesForHashtagOrFolder = Boolean(!files?.length);
  const noFilesMatchingFilter = Boolean(files?.length && !filteredFiles?.length);
  const haveFilesMatchingFilter = Boolean(filteredFiles?.length);

  useUnmount(() => {
    clearFiles();
  });

  // Fires when we navigate an *existing* hashtag
  // after the data of this hashtag is fetched
  useEffect(() => {
    (async () => {
      if (!hashtag || !hashtagData || hasAddedHashtagToProfileRef.current) {
        return;
      }

      const isMissingFromProfile = profile?.hashtags?.every(
        hashtag => hashtag.name !== hashtagData?.name
      );

      if (isMissingFromProfile) {
        await addHashtagToProfile({ ...hashtagData, name: hashtag });
      }

      hasAddedHashtagToProfileRef.current = true;
    })();
  }, [hashtagData, profile?.hashtags, addHashtagToProfile, hashtag]);

  // Initial fetching of the files when the page gets rendered for the first time
  useEffect(() => {
    (async () => {
      const shouldFetchFiles = !alreadyFetchedFiles && !areFilesLoading && hashtag;

      if (shouldFetchFiles) {
        await fetchFiles({ hashtag, folderNames });

        toggleInitialPageLoadingState(false);
      }
    })();
  }, [
    alreadyFetchedFiles,
    areFilesLoading,
    fetchFiles,
    files,
    folderNames,
    hashtag,
    toggleInitialPageLoadingState,
  ]);

  // Fetching more files if available until the scroll bar is visible
  useEffect(() => {
    const noMoreFiles = !files || files.length < FILES_PER_FETCH_SIZE;
    const alreadyFetchingFiles = areFilesLoading || areMoreFilesLoading;

    if (noMoreFiles || alreadyFetchingFiles) return;

    const documentHasScrollbar =
      document.documentElement.scrollHeight > document.documentElement.clientHeight;

    if (!documentHasScrollbar) {
      fetchNextFiles();
    }
  }, [files, fetchNextFiles, areFilesLoading, areMoreFilesLoading]);

  // Show the PasswordProtectionModal
  // If hashtag is protected by password
  // and password hasn't been previously entered during this session
  useEffect(() => {
    const isUserHashtagOwner = hashtagData?.host === profile?.uid;

    if (!hashtag || !hashtagData?.password || isUserHashtagOwner) return;

    if (hashtagData.password && cachedPasswords[hashtag] !== hashtagData?.password) {
      setCanViewFiles(false);
    }
  }, [hashtagData, cachedPasswords, hashtag, profile?.uid]);

  // Clear files and related query data when user leaves the SearchResultsPage
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFilterValue(value);
  };

  const handleRequestClose = () => {
    toggleUploadModalOpenedState();
    setDefaultUploadingFiles([]);
  };

  const handleUploadOptionClick = (option: TUploadOption) => {
    if (option === 'files') {
      toggleUploadModalOpenedState(true);
    }

    if (option === 'folder') {
      toggleNewFolderModalOpenedState(true);
    }
  };

  const checkPassword = async (password: string) => {
    const passwordHash = await sha256(password);

    const isCorrectPassword = hashtagData?.password === passwordHash;

    if (isCorrectPassword) {
      setCanViewFiles(true);

      if (hashtag && passwordHash) {
        setGlobalState({
          cachedPasswords: {
            [hashtag]: passwordHash,
          },
        });
      }
    }

    return isCorrectPassword;
  };

  const renderFiles = (files: Nullable<IFile[]>) => {
    if (!files) return null;

    const filesWithFormattedDates = filteredFiles.map(filteredFile => {
      const createdAtDate = new Timestamp(
        filteredFile.createdAt.seconds,
        filteredFile.createdAt.nanoseconds
      ).toDate();

      const formattedCreatedAtDate = format(createdAtDate, 'dd.MM.yyyy');

      return {
        ...filteredFile,
        createdAtDate,
        formattedCreatedAtDate,
      };
    });

    const filesGroupedByDates = groupBy(filesWithFormattedDates, 'formattedCreatedAtDate');

    const renderedFileGroups = Object.entries(filesGroupedByDates)
      .sort(([dateA], [dateB]) => compareDesc(parseDate(dateA), parseDate(dateB)))
      .map((fileGroup, fileGroupIndex) => {
        const [date, filesInGroup] = fileGroup;
        const isFirstFileGroup = fileGroupIndex === 0;

        return (
          <Fragment key={date}>
            <div className={cn(styles.gridItem, styles.gridItemDate)}>
              {canViewFiles ? date : '01.01.1970'}
            </div>

            {isFirstFileGroup && (
              <UploadFileToggle
                onOptionClick={handleUploadOptionClick}
                isModalOpened={isUploadModalOpened || isNewFolderModalOpened}
              />
            )}

            {filesInGroup
              ?.sort((fileA, fileB) => compareDesc(fileA.createdAtDate, fileB.createdAtDate))
              .map(file => {
                const isSelected = selected.has(file.uid);

                return (
                  <File
                    className={cn({
                      selectable: file.type !== 'folder',
                      [fileStyles.fileSelected]: isSelected,
                    })}
                    key={file.uid}
                    file={file}
                    canViewFile={canViewFiles}
                    data-key={file.uid}
                    isSelected={isSelected}
                  />
                );
              })}
          </Fragment>
        );
      });

    return <>{renderedFileGroups}</>;
  };

  const { t } = useTranslation();

  const shouldShowPagePreloader = initialPageLoadingState || areFilesLoading;
  const isExistingHashtag = Boolean(hashtagData?.uid);
  const isFolder = Boolean(currentFolderName);
  const isExistingFolder = Boolean(folderReference);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <SelectionArea
        className={styles.selectionArea}
        onBeforeStart={onBeforeStart}
        onStart={onStart}
        onMove={onMove}
        selectables=".selectable"
        features={{
          touch: false,
          range: true,
          singleTap: {
            allow: isMobile ? false : true,
            intersect: 'native',
          },
        }}
      >
        <FileActionsBar
          selectedFileUids={selected}
          selectedFiles={filteredFiles.filter(file => selected.has(file.uid))}
        />

        <div className={styles.container}>
          <PageDropzone
            onDropAccepted={handleDropAccepted}
            disabled={
              !isAuthorized ||
              isUploadModalOpened ||
              !isExistingHashtag ||
              (isFolder && !isExistingFolder)
            }
          >
            <div
              className={cn(styles.inputOuterContainer, {
                [styles.inputOuterContainerHidden]: noFilesForHashtagOrFolder,
              })}
            >
              <IconSearch className={styles.searchIcon}></IconSearch>

              <Input
                autoFocus={canViewFiles}
                onChange={handleInputChange}
                type="text"
                className={styles.inputContainer}
                inputClassName={cn(styles.searchInput, {
                  [styles.searchInputLoading]: areFilesLoading,
                })}
                value={filterValue}
                placeholder={t('search')}
                name="hashtag"
              ></Input>
            </div>

            <div className={styles.inputOuterContainerLeft}>
              <h1 className={styles.navTitle}>
                <Link className={styles.goBack} to={folderNames?.length ? previousFolderLink : '/'}>
                  <IconArrowLeft className={styles.goBackIcon}></IconArrowLeft>
                </Link>

                <HashtagPasswordProtection
                  hashtagData={hashtagData}
                  isLoading={shouldShowPagePreloader}
                />

                {Boolean(folderNames?.length) ? (
                  <Link to={`/${hashtag}`}>#{hashtag}</Link>
                ) : (
                  `#${hashtag}`
                )}

                <HashtagReport hashtagData={hashtagData} />

                <Breadcrumbs folderNames={folderNames} hashtag={hashtag} />
              </h1>
            </div>

            {shouldShowPagePreloader ? (
              <div className={styles.centerContainer}>
                <Spinner className={cn(styles.spinner, styles.spinnerBig)} />
              </div>
            ) : (
              <>
                {haveFilesMatchingFilter && (
                  <div className={styles.gridContainer}>
                    <InfiniteScroll
                      scrollThreshold={1}
                      className={styles.filesGrid}
                      dataLength={filteredFiles.length}
                      next={fetchNextFiles}
                      hasMore={hasMore}
                      loader={
                        areMoreFilesLoading && (
                          <div className={cn(styles.afterFiles, styles.infiniteScrollLoader)}>
                            <Spinner className={cn(styles.spinner, styles.infiniteScrollSpinner)} />
                          </div>
                        )
                      }
                    >
                      {renderFiles(filteredFiles)}
                    </InfiniteScroll>
                  </div>
                )}

                {noFilesMatchingFilter && (
                  <div className={styles.centerContainer}>{t('noFilesFound')}</div>
                )}

                {noFilesForHashtagOrFolder && (
                  <div className={styles.centerContainer}>
                    {isExistingHashtag ? (
                      <>
                        {isFolder ? (
                          <>
                            {isExistingFolder ? (
                              <>
                                {t('nofiles')} {t('folder')}
                                <div className={styles.noFilesUploadToggle}>
                                  <UploadFileToggle
                                    onOptionClick={handleUploadOptionClick}
                                    isModalOpened={isUploadModalOpened || isNewFolderModalOpened}
                                  />
                                </div>
                              </>
                            ) : (
                              t('folderDoesntExist')
                            )}
                          </>
                        ) : (
                          <>
                            {t('nofiles')} {t('hashtag')}
                            <div className={styles.noFilesUploadToggle}>
                              <UploadFileToggle
                                onOptionClick={handleUploadOptionClick}
                                isModalOpened={isUploadModalOpened || isNewFolderModalOpened}
                              />
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/*
                        If trying to access a folder in non-existing hashtag, e.g via
                        malformed direct URL, then redirect to the hashtag itself to get rid of the
                        non-existing folder path and show the UI allowing to create this non-existent hashtag
                      */}
                        {currentFolderName ? (
                          <Navigate to={`/${hashtag}`} />
                        ) : (
                          <>
                            <div className={styles.hashtagNotFound}>{t('hashtagDoesntExist')}</div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </PageDropzone>
        </div>
      </SelectionArea>

      <PasswordProtectionModal isOpen={!canViewFiles} checkPassword={checkPassword} />

      {isUploadModalOpened && (
        <UploadFilesModal
          isOpen={true}
          onRequestClose={handleRequestClose}
          defaultFiles={defaultUploadingFiles}
        />
      )}

      <CreateNewFolderModal
        isOpen={isNewFolderModalOpened}
        onRequestClose={toggleNewFolderModalOpenedState}
      />
    </>
  );
};

export default withRemountOnParamChange(SearchResultsPage);
