import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './ContactUs.module.scss';
import Button from 'components/Button';

interface IProps {
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
}

const ContactUs: React.FC<PropsWithChildren<IProps>> = props => {
  const { className, titleClassName, descriptionClassName } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className={cn(styles.container, className)}>
        <div className={styles.textContent}>
          <h4 className={cn(styles.title, titleClassName)}>{t('contactUsTitle')}</h4>
          <div className={cn(styles.description, descriptionClassName)}>{t('contactUsText')}</div>
        </div>

        <a href="mailto:help@hash.cloud" target="_blank" rel="noreferrer">
          <Button type="primary" className={styles.btn}>
            {t('contactUsBtnText')}
          </Button>
        </a>
      </div>
    </>
  );
};

export default ContactUs;
