import {
  AWS_BUCKET_NAME,
  AWS_BUCKET_ORIGIN,
  AWS_REGION,
  CLOUDFRONT_ORIGIN,
} from 'constants/common';

export const getS3PublicObjectUrl = (Key: string) => {
  return `https://${AWS_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/${Key}`;
};

export const getS3ObjectCloudFrontUrl = (Key: string) => {
  return `https://storage.hash.cloud/${Key}`;
};

export const transformS3UrlIfNeeded = (url: string) => {
  const needsToBeTransformed = url.indexOf(AWS_BUCKET_ORIGIN) === 0;

  return needsToBeTransformed ? url.replace(AWS_BUCKET_ORIGIN, CLOUDFRONT_ORIGIN) : url;
};

export const transformFilename = (filename: string) => {
  // Requirement: Reduce repeated spaces to a single space
  filename = filename.replace(/\s+/g, ' ');

  // Characters that might require special handling or should be avoided in S3 object keys
  const specialChars = /[&$@=;/:+,?[\]{}\\^%`|<>#~"]/g;
  const xmlSpecialChars = {
    "'": '&apos;', // XML special character
    '"': '&quot;', // XML special character
    '&': '&amp;', // XML special character
    '<': '&lt;', // XML special character
    '>': '&gt;', // XML special character
    '\r': '&#13;', // Carriage return
    '\n': '&#10;', // Line feed
  };

  // Requirement: Replace XML special characters with their encoded equivalents
  filename = filename.replace(
    /['"&<>\r\n]/g,
    char => xmlSpecialChars[char as keyof typeof xmlSpecialChars]
  );

  // Requirement: Replace characters that require special handling with URL encoding
  filename = filename.replace(specialChars, char => '%' + char.charCodeAt(0).toString(16));

  // Requirement: Ensure the filename does not start with "./" or "../"
  if (filename.startsWith('./') || filename.startsWith('../')) {
    filename = filename.replace(/^\.\//, '').replace(/^\.\.\//, '');
  }

  // Requirement: Remove trailing periods
  filename = filename.replace(/\.+$/, '');

  return filename;
};
