import { useCallback } from 'react';

import { MAX_FILES_PER_UPLOAD } from 'constants/common';

type TOnDrop = (files: File[]) => void;

const useHandleDrop = (onDropAccepted: TOnDrop) => {
  const handleDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      const filteredFiles = acceptedFiles.slice(0, MAX_FILES_PER_UPLOAD);

      onDropAccepted?.(filteredFiles);
    },
    [onDropAccepted]
  );

  return handleDropAccepted;
};

export default useHandleDrop;
