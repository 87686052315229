import { ReactNode } from 'react';
import cn from 'classnames';
import ReactModal, { Classes } from 'react-modal';
import isString from 'lodash/isString';

import { ReactComponent as IconClose } from 'images/icons/cross.svg';

import styles from './Modal.module.scss';

interface IProps extends ReactModal.Props {
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  onAfterClose?: () => void;
  overlayClassName?: string | Classes;
  showCloseButton?: boolean;
}

const Modal = (props: IProps) => {
  const {
    children,
    className,
    contentClassName,
    isOpen,
    onAfterClose,
    onRequestClose,
    overlayClassName,
    showCloseButton,
    parentSelector,
    htmlOpenClassName,
  } = props;

  return (
    <>
      <ReactModal
        parentSelector={parentSelector}
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={onRequestClose}
        onAfterClose={onAfterClose}
        shouldFocusAfterRender={false}
        htmlOpenClassName={htmlOpenClassName || 'overflowHidden'}
        className={cn(styles.modal, className)}
        closeTimeoutMS={200}
        overlayClassName={
          isString(overlayClassName)
            ? {
                base: cn(styles.modalOverlay, overlayClassName),
                afterOpen: styles.modalOverlayAfterOpen,
                beforeClose: styles.modalOverlayBeforeClose,
              }
            : {
                base: cn(styles.modalOverlay, overlayClassName?.base),
                afterOpen: overlayClassName?.afterOpen || styles.modalOverlayAfterOpen,
                beforeClose: overlayClassName?.beforeClose || styles.modalOverlayBeforeClose,
              }
        }
      >
        {showCloseButton && (
          <div className={styles.modalClose} onClick={onRequestClose}>
            <IconClose className={styles.modalCloseIcon} />
          </div>
        )}

        <div className={cn(styles.modalContent, contentClassName)}>{children}</div>
      </ReactModal>
    </>
  );
};

export default Modal;
