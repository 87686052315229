import { MouseEvent, ReactNode, Ref, forwardRef } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

import { TButtonType } from 'types/interfaces';
import Spinner from 'components/Spinner';

export interface IButtonBaseProps {
  className?: string;
  iconClassName?: string;
  htmlType?: 'submit' | 'button';
  type: TButtonType;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  isLoading?: boolean;
}

export interface IButtonPropsWithText extends IButtonBaseProps {
  text: ReactNode;
  children?: never;
}

export interface IButtonPropsWithChildren extends IButtonBaseProps {
  children: ReactNode;
  text?: never;
}

export type TButtonProps = IButtonPropsWithText | IButtonPropsWithChildren;

const Button = (props: TButtonProps, ref: Ref<HTMLButtonElement>) => {
  const {
    children,
    className,
    disabled,
    htmlType,
    iconClassName,
    iconLeft,
    iconRight,
    isLoading,
    onClick,
    text,
    type = 'primary',
  } = props;

  const buttonText = text || children;
  const hasIcon = iconRight || iconLeft;

  return (
    <button
      ref={ref}
      className={cn(className, styles.button, type ? styles[type] : null, {
        [styles.disabled]: disabled || isLoading,
        [styles.loading]: isLoading,
      })}
      type={htmlType || 'button'}
      disabled={disabled}
      onClick={disabled || isLoading ? undefined : onClick}
    >
      {isLoading && <Spinner className={styles.spinner} />}

      {iconLeft && (
        <span className={cn(styles.icon, styles.iconLeft, iconClassName)}>{iconLeft}</span>
      )}

      {hasIcon ? <span className={styles.text}>{buttonText}</span> : buttonText}

      {iconRight && (
        <span className={cn(styles.icon, styles.iconRight, iconClassName)}>{iconRight}</span>
      )}
    </button>
  );
};

export default forwardRef(Button);
