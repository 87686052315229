import {
  AuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';
import { Timestamp, doc, getDoc, setDoc } from 'firebase/firestore';
import { useMount } from 'react-use';

import useGlobalState from './useGlobalState';

import { db } from 'utils/firestore';
import consoleLogDev from 'utils/consoleLogDev';

import useSubscription from 'hooks/useSubscription';

import { DEFAULT_PLAN_STORAGE_SIZE_IN_MB } from 'constants/common';

import { IProfile, Nullable } from 'types/interfaces';
import { sendDataLayerEvent } from 'utils/dataLayer';

interface IParams {
  shouldPerformAuth?: boolean;
}

interface IUserCredentialExtended extends UserCredential {
  _tokenResponse: Record<string, string>;
}

const useAuth = (params: IParams = {}) => {
  const { shouldPerformAuth } = params;

  const { refreshSubscriptionData } = useSubscription();

  const [globalState, setGlobalState] = useGlobalState();
  const { isAuthorized } = globalState;

  const fetchProfile = async (uid: string) => {
    const profileDocRef = doc(db, 'profiles', uid);
    const profileDocSnapshot = await getDoc(profileDocRef);

    const profileData = profileDocSnapshot?.data() as Nullable<IProfile>;

    return profileData;
  };

  const populateProfile = async (userCredential: UserCredential) => {
    try {
      const user = userCredential.user;

      const docData = {
        createdAt: Timestamp.now(),
        profileImageUrl: '',
        name: {
          customUsername: user.providerData[0]?.displayName?.replaceAll(' ', '') || '',
          first: (userCredential as IUserCredentialExtended)._tokenResponse?.firstName || '',
          last: (userCredential as IUserCredentialExtended)?._tokenResponse.lastName || '',
        },
        uid: user.uid,
        usedStorageInMB: 0,
        planStorageInMB: DEFAULT_PLAN_STORAGE_SIZE_IN_MB,
        hashtags: [],
      };

      await setDoc(doc(db, 'profiles', user.uid), docData);

      return docData;
    } catch (error) {
      return null;
    }
  };

  const signIn = async (provider: AuthProvider) => {
    const auth = getAuth();

    try {
      setGlobalState({ isAuthModalOpened: false });

      const result = await signInWithPopup(auth, provider);

      setGlobalState({ isAuthLoading: true });

      const user = result.user;

      let profile = await fetchProfile(user.uid);

      if (!profile) {
        profile = await populateProfile(result);

        sendDataLayerEvent('create_profile');
      }

      setGlobalState({
        currentUser: user,
        isAuthorized: true,
        profile,
        isAuthLoading: false,
      });
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      consoleLogDev(`${errorCode}: ${errorMessage}`);

      setGlobalState({
        currentUser: null,
        isAuthorized: false,
        isAuthLoading: false,
        profile: null,
      });
    }
  };

  const signInWithGoogle = () => {
    const googleAuthProvider = new GoogleAuthProvider();

    signIn(googleAuthProvider);
  };

  const signInWithApple = async () => {
    const appleOAuthProvider = new OAuthProvider('apple.com');

    signIn(appleOAuthProvider);
  };

  const showAuthModal = () => {
    setGlobalState({ isAuthModalOpened: true });
  };

  useMount(() => {
    if (!shouldPerformAuth) return;

    setGlobalState({ isAuthLoading: true });
    const auth = getAuth();

    onAuthStateChanged(auth, async user => {
      if (user) {
        const uid = user.uid;

        const profile = await fetchProfile(uid);

        setGlobalState({ currentUser: user, isAuthorized: true, isAuthLoading: false, profile });

        refreshSubscriptionData(profile);
      } else {
        setGlobalState({
          currentUser: null,
          isAuthorized: false,
          isAuthLoading: false,
          profile: null,
        });
      }
    });
  });

  return {
    showAuthModal,
    signInWithGoogle,
    signInWithApple,
    fetchProfile,
    isAuthorized,
  };
};

export default useAuth;
