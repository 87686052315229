import { PropsWithChildren, useEffect } from 'react';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './CookieYesSettingsButton.module.scss';

interface IProps {}

const CookieYesSettingsButton: React.FC<PropsWithChildren<IProps>> = _props => {
  const [isCookieYesLoaded, toggleLoadedState] = useToggle(Boolean(window.cookieyes));
  const { t } = useTranslation();

  useEffect(() => {
    if (window.cookieyes) {
      toggleLoadedState(true);
    }

    const handler = () => {
      toggleLoadedState(true);
    };

    document.addEventListener('cookieyes_banner_load', handler);

    return () => {
      document.removeEventListener('cookieyes_banner_load', handler);
    };
  }, [toggleLoadedState]);

  return (
    <>
      {isCookieYesLoaded ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className={cn('cky-banner-element', styles.link)}>{t('cookieSettings')}</a>
      ) : null}
    </>
  );
};

export default CookieYesSettingsButton;
