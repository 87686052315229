import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as IconGoogle } from 'images/icons/google.svg';

import useAuth from 'hooks/useAuth';

import styles from './SignInWithGoogleButton.module.scss';

interface IProps {
  className?: string;
  children?: ReactNode;
}

const SignInWithGoogleButton = (props: IProps) => {
  const { t } = useTranslation();

  const { className, children = t('signInWithGoogle') } = props;

  const { signInWithGoogle, isAuthorized } = useAuth();

  return isAuthorized ? null : (
    <>
      <button className={cn(styles.btn, className)} onClick={signInWithGoogle}>
        <IconGoogle className={styles.icon} />

        <span className={styles.text}>{children}</span>
      </button>
    </>
  );
};

export default SignInWithGoogleButton;
