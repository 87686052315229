import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'motion/react';
import { Trans, useTranslation } from 'react-i18next';
import { useInterval, useLifecycles, usePrevious, useToggle } from 'react-use';
import { useNavigate, generatePath } from 'react-router-dom';
import cn from 'classnames';
import Typewriter from 'typewriter-effect';

import Button from 'components/Button';
import CreateNewHashtag from 'components/CreateNewHashtag';
import Footer from 'components/Footer';
import Input from 'components/Input';
import Slideshow from 'components/Slideshow';
import Spinner from 'components/Spinner';

import { ReactComponent as IconCollapse } from 'images/icons/collapse.svg';
import { ReactComponent as IconExpand } from 'images/icons/expand.svg';
import { ReactComponent as IconAppStore } from 'images/icons/AppStore.svg';

import useAuth from 'hooks/useAuth';
import useFetchFiles from 'hooks/useFetchFiles';
import useGlobalState from 'hooks/useGlobalState';

import { SEARCH_RESULTS_PAGE_ROUTE } from 'constants/routes';

import { sendDataLayerEvent } from 'utils/dataLayer';

import styles from './IndexPage.module.scss';

const opacityExitDuration = 0.1;
const opacityInDuration = 0.2;
const opacityInDelay = 0.1;
const heightAnimDuration = 0.2;

const animation = {
  initial: { opacity: 0, height: 0 },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      height: {
        duration: heightAnimDuration,
      },
      opacity: {
        duration: opacityInDuration,
        delay: opacityInDelay,
      },
    },
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      height: {
        duration: heightAnimDuration,
      },
      opacity: {
        duration: opacityExitDuration,
      },
    },
  },
};

const IndexPage = () => {
  const navigate = useNavigate();
  const [globalState] = useGlobalState();
  const { isAuthLoading, isAuthorized } = globalState;

  const { showAuthModal } = useAuth();
  const { fetchFiles, isLoading, files } = useFetchFiles();

  const [hashtag, setHashtag] = useState('');
  const [isFormExpanded, toggleExpandedState] = useToggle(
    matchMedia('(min-height: 1050px)').matches
  );

  const prevIsDataLoading = usePrevious(isLoading);

  const [isVisible, setIsVisible] = useState(true);

  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value !== '') {
      setIsVisible(false);
    }
    setHashtag(value.replaceAll('#', ''));
  };

  const handleEnterKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Enter') return;

    if (searchButtonRef.current) {
      searchButtonRef.current.click();
    }
  }, []);

  const handleSearchClick = () => {
    if (!hashtag) return;

    sendDataLayerEvent('show_files');

    fetchFiles({ hashtag });
  };

  const toggleFormState = (state: boolean) => {
    if (state && !localStorage.getItem('ha_touched_form')) {
      localStorage.setItem('ha_touched_form', '1');
    }

    toggleExpandedState(state);
  };

  const handleFocusChange = (newState: boolean) => () => {
    toggleFormState(newState);
  };

  const wiggleForm = () => {
    const animationDuration = 150;
    const animationIterationCount = 5;
    const animationClassName = ['wiggle-animation', styles.formHighlighted];

    const animantedElement = document.querySelector<HTMLDivElement>(`.${styles.form}`);

    if (animantedElement) {
      animantedElement.style.animationDuration = `${animationDuration}ms`;
      animantedElement.style.animationIterationCount = `${animationIterationCount}`;
      animantedElement.classList.add(...animationClassName);

      setTimeout(() => {
        animantedElement.classList.remove(...animationClassName);
      }, animationDuration * animationIterationCount);
    }
  };

  useInterval(() => {
    if (!localStorage.getItem('ha_touched_form')) {
      wiggleForm();
    }
  }, 7_000);

  useEffect(() => {
    const isJustFinishedLoading = prevIsDataLoading && !isLoading;
    if (isJustFinishedLoading) {
      navigate(generatePath(SEARCH_RESULTS_PAGE_ROUTE, { hashtag }));
    }
  }, [prevIsDataLoading, isLoading, hashtag, navigate, files]);

  useLifecycles(
    () => {
      document.addEventListener('keypress', handleEnterKeyPress);
    },
    () => {
      document.removeEventListener('keypress', handleEnterKeyPress);
    }
  );

  const { t } = useTranslation();

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.isGuest]: !isAuthLoading && !isAuthorized,
          [styles.isAuthorized]: !isAuthLoading && !isAuthorized,
        })}
      >
        <div className={styles.buttons}>
          {!isAuthLoading && !isAuthorized && (
            <Button type="dark" className={cn(styles.signInBtn)} onClick={showAuthModal}>
              {t('signIn')}
            </Button>
          )}

          <a
            className={styles.appStoreLink}
            href="https://apps.apple.com/us/app/hashcloud-cloud-storage/id1619279459?itsct=apps_box_link&itscg=30200"
            target="_blank"
            rel="noreferrer"
          >
            <IconAppStore className={styles.appStoreLinkIcon}></IconAppStore>
          </a>
        </div>

        <div className={styles.containerInner}>
          <div className={styles.centerContainer}>
            <div className={styles.titles}>
              <h2 className={styles.title}>
                <Trans
                  i18nKey="mainPageTitle"
                  components={{
                    highlight: <span className={styles.highlight} />,
                  }}
                />
              </h2>

              <h3 className={styles.subtitle}>
                <span className={styles.subtitleLine}>
                  <Trans i18nKey="mainPageSubtitleList" />
                </span>

                <span className={styles.subtitleLine}>
                  <Trans
                    i18nKey="mainPageSubtitleLinks"
                    values={{
                      webLink: 'https://www.hash.cloud/',
                      appLink:
                        'https://apps.apple.com/us/app/hashcloud-cloud-storage/id1619279459?itsct=apps_box_link&itscg=30200',
                    }}
                    components={{
                      boldlink: <span className={styles.boldlink} />,
                      // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                      a: <a />,
                    }}
                  />
                </span>
              </h3>
            </div>
            <Slideshow className={styles.slideshow} />

            <div className={cn(styles.form, { [styles.formExpanded]: isFormExpanded })}>
              <div
                className={styles.formIconContainer}
                onClick={() => toggleFormState(!isFormExpanded)}
              >
                {isFormExpanded ? (
                  <IconCollapse className={styles.formIcon} />
                ) : (
                  <IconExpand className={styles.formIcon} />
                )}
              </div>

              <h3 className={styles.formTitle}>{t('mainPageFormTitle')}</h3>
              <div className={styles.searchInputGroup}>
                {isLoading && (
                  <div className={styles.spinnerContainer}>
                    <Spinner className={styles.spinner} />
                  </div>
                )}

                <Input
                  autoComplete="off"
                  onFocus={handleFocusChange(true)}
                  onChange={handleValueChange}
                  value={hashtag ? '#' + hashtag : ''}
                  className={styles.inputContainer}
                  inputClassName={styles.searchInput}
                  name="hashtag"
                  type="text"
                  placeholder="#hashtag"
                  disabled={isLoading}
                />

                {!isLoading && isVisible && (
                  <div className={styles.infoGroup}>
                    <Typewriter
                      onInit={typewriter => {
                        typewriter.pauseFor(1000).deleteAll().start();
                      }}
                      options={{
                        loop: true,
                        strings: [
                          '#atheUebung1_TU',
                          '#famMuellerItal24',
                          '#uniDueMakroWS23',
                          '#steuerMueller23',
                        ],
                        autoStart: true,
                        wrapperClassName: styles.typeWriterText,
                        cursorClassName: styles.cursor,
                      }}
                    />
                  </div>
                )}
              </div>

              <AnimatePresence initial={false}>
                {isFormExpanded && (
                  <motion.div {...animation} key="a1">
                    <div className={cn(styles.formButtons)}>
                      <Button
                        type="flat"
                        className={cn(styles.btn, styles.btnShow)}
                        onClick={handleSearchClick}
                        disabled={isLoading}
                        ref={searchButtonRef}
                      >
                        {t('mainPageFormBtn1Text')}
                      </Button>
                      <CreateNewHashtag
                        key={hashtag}
                        defaultHashtag={hashtag}
                        btnClassName={cn(styles.btn, styles.btnCreate)}
                        disabled={isLoading}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>

        <Footer theme="dark" />
      </div>
    </>
  );
};

export default IndexPage;
