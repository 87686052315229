import { S3Client } from '@aws-sdk/client-s3';
import { ReactNode, useState } from 'react';

import { Nullable } from 'types/interfaces';

import S3Context from 'utils/S3Context';

interface IProps {
  children: ReactNode;
}

const S3Provider = (props: IProps) => {
  const { children } = props;
  const [s3Client, setS3Client] = useState<Nullable<S3Client>>(null);
  const [isS3ClientLoading, setS3ClientLoadingState] = useState(false);

  return (
    <S3Context.Provider
      value={{ s3Client: s3Client, setS3Client, isS3ClientLoading, setS3ClientLoadingState }}
    >
      {children}
    </S3Context.Provider>
  );
};

export default S3Provider;
