import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';

import styles from './Help.module.scss';
import Button from 'components/Button';
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Footer from 'components/Footer';
import useGlobalState from 'hooks/useGlobalState';

interface IProps {}

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  hashtag: yup.string().optional(),
  inquiry: yup.string().required(),
});

type TFieldValues = yup.InferType<typeof schema>;

const Help: React.FC<PropsWithChildren<IProps>> = _props => {
  const { t } = useTranslation();
  const [globalState, _setGlobalState] = useGlobalState();
  const { profile, currentUser } = globalState;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      email: currentUser?.email || '',
      name: profile?.name.customUsername,
    },
  });

  const onSubmit = (values: TFieldValues) => {
    const { hashtag, name, inquiry, email } = values;

    const subject = 'Support request';
    const today = new Date();

    const formattedDate = today.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const body = `
Date of Request: ${formattedDate}
Contact Name: ${name}
Contact Email: ${email}
${hashtag ? `Hashtag Name: ${hashtag}` : ''}

${inquiry}`;

    const url = `mailto:help@hash.cloud?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body.trim())}`;

    window.location.assign(url);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titles}>
          <h1 className={styles.title}>{t('helpPageTitle')}</h1>
          <h3 className={styles.subtitle}>{t('helpPageSubtitle')}</h3>
        </div>

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            className={styles.input}
            inputClassName={cn(styles.inputInner, { [styles.hasError]: errors.name })}
            labelClassName={styles.inputLabel}
            placeholder=" "
            label={t('name')}
            {...register('name', { maxLength: 100 })}
          />
          <Input
            className={styles.input}
            inputClassName={cn(styles.inputInner, { [styles.hasError]: errors.email })}
            labelClassName={styles.inputLabel}
            placeholder=" "
            label={t('email')}
            hasError={errors.email}
            {...register('email')}
          />
          <Input
            inputClassName={cn(styles.inputInner, { [styles.hasError]: errors.hashtag })}
            labelClassName={styles.inputLabel}
            placeholder=" "
            className={styles.input}
            hasError={errors.hashtag}
            label={
              <>
                Hashtag · <span>{t('optional')}</span>
              </>
            }
            {...register('hashtag')}
          />
          <Textarea
            labelClassName={styles.inputLabel}
            inputClassName={cn(styles.inputInner, styles.textareaInner, {
              [styles.hasError]: errors.inquiry,
            })}
            placeholder=" "
            className={cn(styles.input, styles.textarea)}
            label={t('inquiry')}
            name="inquiry"
            control={control}
            hasError={errors.inquiry}
          />

          <div className={styles.buttons}>
            <Button type="flat" className={cn(styles.btn, styles.submitBtn)} htmlType="submit">
              {t('send')}
            </Button>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default Help;
