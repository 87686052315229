import { and, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { CopyObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { FocusEvent, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import cn from 'classnames';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import useGlobalState from 'hooks/useGlobalState';
import useS3 from 'hooks/useS3';

import { filesCollection } from 'utils/firestore';
import {
  showDefaultErrorNotification,
  showErrorNotification,
  showSuccessfulNotification,
} from 'utils/notifications';
import { getS3ObjectCloudFrontUrl } from 'utils/aws';

import { AWS_BUCKET_NAME } from 'constants/common';

import { IFile } from 'types/interfaces';

import styles from './FileRenameModal.module.scss';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  file: IFile;
}

const schema = yup.object().shape({
  filename: yup.string().required(),
});

type TFieldValues = yup.InferType<typeof schema>;

const FileRenameModal = (props: IProps) => {
  const { isOpen, onRequestClose, file } = props;

  const { t } = useTranslation();
  const retryIndexRef = useRef(0);
  const { s3Client, getFileS3ObjectKey } = useS3();
  const [globalState, setGlobalState] = useGlobalState();
  const { folderReference, hashtagData, files } = globalState;

  const [isLoading, toggleLoadingState] = useToggle(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setValue,
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      filename: file.name,
    },
  });
  const hasError = Boolean(errors.filename);
  const currentFilenameInputValue = watch('filename');

  const checkFilenameAvailability = async (filename: string, hashtagUid: string) => {
    const filesQuery = query(
      filesCollection,
      and(
        where('hashtagUid', '==', hashtagUid),
        where('folderReference', '==', folderReference || ''),
        where('name', '==', filename)
      )
    );
    const foldersSnapshot = await getDocs(filesQuery);
    const folders = foldersSnapshot.docs.map(folder => folder.data() as IFile);

    return folders.length === 0;
  };

  useUpdateEffect(() => {
    if (isOpen) {
      setValue('filename', file.name);
    }
  }, [isOpen]);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      event.target.select();
    }, 100);
  };

  const updateFileOnUi = (name: string, url?: string) => {
    const nextFiles = cloneDeep(files) || [];

    const indexToUpdate = files!.findIndex(storedFile => storedFile.uid === file.uid);

    if (indexToUpdate > -1) {
      const updatedData = {
        name,
        ...(url && { url }),
      };
      nextFiles[indexToUpdate] = { ...nextFiles[indexToUpdate], ...updatedData };
    }

    setGlobalState({ files: nextFiles });
  };

  const updateFileInFirestore = async (name: string, url?: string) => {
    const fileDocRef = doc(filesCollection, file.uid);

    await updateDoc(fileDocRef, { name, ...(url && { url }) });
  };

  const updateFileInS3 = async (
    newName: string,
    encodeFileName?: boolean,
    useFullPath?: boolean
  ) => {
    // In order to rename the file (update the key) in the s3
    // we need to copy it to the new key and delete the old object
    if (!s3Client) {
      throw new Error(t('failedConnectToCloud'), { cause: 'AWS' });
    }

    const oldKey = getFileS3ObjectKey(file, encodeFileName, useFullPath);
    const newKey = getFileS3ObjectKey({ ...file, name: newName });

    if (!oldKey || !newKey) {
      throw new Error("Can't retrieve s3 object Key for this file");
    }

    const copyParams = {
      Bucket: AWS_BUCKET_NAME,
      CopySource: encodeURIComponent(`${AWS_BUCKET_NAME}/${oldKey}`),
      Key: newKey,
    };
    await s3Client.send(new CopyObjectCommand(copyParams));

    const deleteParams = {
      Bucket: AWS_BUCKET_NAME,
      Key: oldKey,
    };
    await s3Client.send(new DeleteObjectCommand(deleteParams));

    const newObjectUrl = getS3ObjectCloudFrontUrl(newKey);

    return newObjectUrl;
  };

  const renameFile = async (name: string, encodeFileName?: boolean, useFullPath?: boolean) => {
    if (!hashtagData || !name) return;

    try {
      toggleLoadingState();

      const isAvailable = await checkFilenameAvailability(name, hashtagData.uid);

      if (!isAvailable) {
        setError('filename', {
          type: 'custom',
          message: t('nameIsAlreadyTaken'),
        });

        return;
      }

      let newUrl;
      if (file.type !== 'folder') {
        newUrl = await updateFileInS3(name, encodeFileName, useFullPath);
      }

      await updateFileInFirestore(name, newUrl);
      updateFileOnUi(name, newUrl);

      onRequestClose();
      showSuccessfulNotification(
        t('fileWasSuccessfullyRenamed', {
          type: file.type === 'folder' ? t('folderType') : t('fileType'),
        })
      );
    } catch (error) {
      if (error.name === 'NoSuchKey') {
        // If the s3 object with the specified Key isn't found it's possible that it's using one of the old/wrong Key formats
        // where either 1) the file.name part of the key was encoded or 2) full path to the file was used instead of just its folderReference
        // so we should re-try the operation couple more times

        if (retryIndexRef.current === 0) {
          renameFile(name, true);
          retryIndexRef.current += 1;
        } else {
          renameFile(name, false, true);
        }
      } else if (error.cause === 'AWS') {
        showErrorNotification(t('failedConnectToCloud'));
      } else {
        showDefaultErrorNotification();
      }
    } finally {
      toggleLoadingState();
    }
  };

  const onSubmit = async ({ filename: name }: TFieldValues) => {
    renameFile(name);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className={styles.modal}
        contentClassName={styles.modalContent}
        showCloseButton
      >
        <h2 className={styles.title}>{t('fileRenameModalTitle')}</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            autoFocus
            onFocus={handleFocus}
            placeholder=""
            inputClassName={styles.input}
            hasError={hasError}
            error={errors.filename?.type === 'custom' ? errors.filename?.message : undefined}
            {...register('filename', { max: 150 })}
          />

          <div className={styles.btnContainer}>
            <Button
              type="secondary"
              className={cn(styles.btn, styles.cancelBtn)}
              onClick={onRequestClose}
            >
              {t('cancel')}
            </Button>
            <Button
              type="primary"
              className={cn(styles.btn, styles.submitBtn)}
              htmlType="submit"
              disabled={
                hasError || !currentFilenameInputValue || currentFilenameInputValue === file.name
              }
              isLoading={isLoading}
            >
              {t('submit')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default FileRenameModal;
