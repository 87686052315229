import { Subscription } from '@invertase/firestore-stripe-payments';
import { Timestamp } from 'firebase/firestore';
import { ChangeEventHandler, KeyboardEvent, ReactNode } from 'react';

export type Nullable<T> = T | null;

export type TButtonType = 'primary' | 'secondary' | 'text' | 'dark' | 'flat';

interface ITimestampLike {
  seconds: number;
  nanoseconds: number;
}

export interface IInputProps<T> {
  name: string;
  label?: ReactNode;
  value?: T;
  onChange?: ChangeEventHandler;
  onKeyUp?: (event: KeyboardEvent) => void;
  required?: boolean;
  defaultValue?: T;
}

export interface IFile {
  name: string;
  sizeInMB: number;
  type: string;
  url: string;
  uid: string;
  ownerUid: string;
  username?: string;
  folderReference: string;
  createdAt: ITimestampLike | Timestamp;
}

export interface IHashtagData {
  createdAt: ITimestampLike | Timestamp;
  host: string;
  id: string;
  name: string;
  password: string;
  uid: string;
}

export interface IProfile {
  createdAt: ITimestampLike | Timestamp;
  profileImageUrl: string;
  name: {
    customUsername: string;
    first: string;
    last: string;
  };
  uid: string;
  planStorageInMB: number;
  usedStorageInMB: number;
  hashtags?: IHashtagData[];
  hasWebSubscription?: boolean;
  hasiOSSubscription?: boolean;
  hasAndroidSubscription?: boolean;
}

export type TFiles = Nullable<IFile[]>;

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T; // from lodash

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export interface IProduct {
  active: boolean;
  description: string;
  metadata: {
    planStorageInMB?: string;
    planStorageInGB?: string;
    name?: string;
    description?: string;
  };
  images: string[];
  name: string;
  role: Nullable<string>;
  tax_code: string;
}

export const billingPeriodOptions = ['month', 'year'] as const;
export type TBillingPeriod = (typeof billingPeriodOptions)[number];

export interface IPrice {
  active: boolean;
  billing_scheme: 'per_unit';
  currency: string;
  description: string | null;
  interval: TBillingPeriod;
  interval_count: number;
  metadata: {
    product: string;
  };
  product: string;
  recurring: {
    aggregate_usage: null;
    interval: 'month' | 'year';
    interval_count: number;
    meter: null;
    trial_period_days: null;
    usage_type: string;
  };
  tax_behavior: 'inclusive';
  trial_period_days: number | null;
  type: 'recurring' | 'one_time';
  unit_amount: number;
}

export interface ISubscriptionItem {
  id: string;
}

export interface ISubscription extends Subscription {
  items: ISubscriptionItem[];
}

export interface IProductWithId extends IProduct {
  productId: string;
}
export interface IPriceWithId extends IPrice {
  priceId: string;
}

export type TPrices = {
  [key: string]: IPrice;
};

export interface IProductWithPrices extends IProduct {
  productId: string;
  prices: TPrices;
}

export interface ICustomerPortalSessionParams {
  returnUrl?: string;
  locale?: string;
  configuration?: string;
  flow_data?: Record<string, any>;
}

export interface ICustomerPortalSessionResponse {
  id: string;
  object: 'billing_portal.session';
  configuration: string;
  created: EpochTimeStamp;
  customer: string;
  flow: unknown;
  livemode: boolean;
  locale: Nullable<string>;
  on_behalf_of: unknown;
  return_url: Nullable<string>;
  url: string;
}
