import { PropsWithChildren, useState } from 'react';
import cn from 'classnames';
// import { useTranslation } from 'react-i18next';

import screenshot1 from 'images/indexPage/screenshot1.webp';
import screenshot2 from 'images/indexPage/screenshot2.webp';
import screenshot3 from 'images/indexPage/screenshot3.webp';
import screenshot4 from 'images/indexPage/screenshot4.webp';

import styles from './Slideshow.module.scss';
import { useInterval } from 'react-use';

interface IProps {
  className?: string;
}

const slides = [screenshot1, screenshot2, screenshot3, screenshot4];

const Slideshow: React.FC<PropsWithChildren<IProps>> = props => {
  const { className } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  useInterval(() => {
    setActiveIndex((activeIndex + 1) % slides.length);
  }, 4_500);

  return (
    <>
      <div className={cn(styles.container, className)}>
        <div className={styles.innerContainer}>
          {slides.map((slide, slideIndex) => {
            const isActive = activeIndex === slideIndex;

            return (
              <img
                key={slideIndex}
                src={slide}
                alt={`slide ${slideIndex}`}
                className={cn(styles.slide, { [styles.slideActive]: isActive })}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Slideshow;
