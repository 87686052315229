import { ChangeEvent, useEffect, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useGlobalState from 'hooks/useGlobalState';

import { ReactComponent as IconLock } from 'images/icons/lock.svg';
import { ReactComponent as IconOpenedLock } from 'images/icons/openedLock.svg';

import { IHashtagData, Nullable } from 'types/interfaces';

import { db } from 'utils/firestore';
import { showDefaultErrorNotification, showSuccessfulNotification } from 'utils/notifications';
import sha256 from 'utils/sha256';

import Button from 'components/Button';
import Modal from 'components/Modal';
import PasswordInput from 'components/PasswordInput';
import Spinner from 'components/Spinner';

import styles from './HashtagPasswordProtection.module.scss';

interface IProps {
  hashtagData: Nullable<IHashtagData>;
  isLoading: boolean;
}

const HashtagPasswordProtection = (props: IProps) => {
  const { hashtagData } = props;

  const { t } = useTranslation();
  const [globalState, setGlobalState] = useGlobalState();

  const [isLoading, toggleLoadingState] = useToggle(false);
  const [isModalOpen, toggleModalState] = useToggle(false);

  const [password, setPassword] = useState('');

  const { profile } = globalState;

  useEffect(() => {
    if (!isModalOpen) {
      setPassword('');
    }
  }, [isModalOpen]);

  const updateHashtagPassword = async (password: string) => {
    if (!hashtagData?.uid) return;

    try {
      const newPassword = password ? await sha256(password) : '';

      toggleLoadingState(true);
      await updateDoc(doc(db, 'hashtags', hashtagData.uid), {
        password: newPassword,
      });
      setGlobalState({
        hashtagData: { ...hashtagData, password },
      });
    } catch (error) {
      showDefaultErrorNotification();
    } finally {
      toggleLoadingState(false);
      toggleModalState(false);
    }
  };

  const resetHashtagPassword = async () => {
    await updateHashtagPassword('');
  };

  const handleClick = async () => {
    if (!hashtagData?.password) {
      toggleModalState();
    } else {
      await resetHashtagPassword();
      showSuccessfulNotification(t('hashtagPasswordReset'));
    }
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setPassword(value);
  };

  const handleSubmitBtnClick = async () => {
    await updateHashtagPassword(password);
    showSuccessfulNotification(t('hashtagPasswordUpdated'));
  };

  if (!profile || profile.uid !== hashtagData?.host) {
    return <></>;
  }

  return (
    <>
      <div
        className={cn(styles.container)}
        onClick={handleClick}
        title={t(hashtagData?.password ? 'removePasswordProtection' : 'protectWithPassword')}
      >
        {isLoading ? (
          <Spinner className={styles.spinner} />
        ) : (
          <>
            {hashtagData?.password ? (
              <IconLock className={styles.icon}></IconLock>
            ) : (
              <IconOpenedLock className={styles.icon}></IconOpenedLock>
            )}
          </>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModalState}
        className={styles.modal}
        showCloseButton
      >
        <h2 className={styles.title}>{t('setHashtagPasswordModalTitle')}</h2>

        <PasswordInput
          name="password"
          required
          className={styles.input}
          autoFocus
          onChange={handlePasswordChange}
          value={password}
          autoComplete="new-password"
        ></PasswordInput>

        <div className={styles.btnContainer}>
          <Button
            type="secondary"
            className={cn(styles.btn, styles.cancelBtn)}
            onClick={toggleModalState}
          >
            {t('cancel')}
          </Button>
          <Button
            type="primary"
            className={cn(styles.btn, styles.submitBtn)}
            onClick={handleSubmitBtnClick}
            disabled={!password}
            isLoading={isLoading}
          >
            {t('submit')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default HashtagPasswordProtection;
