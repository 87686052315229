import * as yup from 'yup';

import { isBefore, isValid } from 'date-fns';

import { getTodaysDate } from 'utils/date';

yup.addMethod(yup.date, 'isValidPastDate', function (customMessage) {
  return this.test('isValidPastDate', customMessage || 'dd.MM.yyyy', value => {
    if (!value) return true;

    return isValid(value) && isBefore(value, getTodaysDate());
  });
});

yup.addMethod(yup.string, 'isValidHashtag', function (customMessage) {
  return this.test('isValidHashtag', customMessage, hashtag => {
    if (!hashtag) return true;

    const isValidLength = hashtag.length > 6;

    const atLeastOneDigit = '(?=.*\\d)';
    const notSpecialCharactersOrWhitespace = '[^\\s!@#$%^&*()_+{}[\\]:;"\'<>,.?/|`~-]+';

    const validHashtagRegex = new RegExp(
      '^' + atLeastOneDigit + notSpecialCharactersOrWhitespace + '$'
    );

    const isValidHashtag = isValidLength && validHashtagRegex.test(hashtag);

    return isValidHashtag;
  });
});

yup.addMethod(yup.string, 'isValidUsername', function (customMessage) {
  return this.test('isValidUsername', customMessage, username => {
    if (!username) return true;

    const isValidLength = username.length > 3;

    const restrictedSpecialCharacters = '[^\\s!@#$%^&*()+{}[\\]:;"\'<>,.?/|`~]';

    const validUsernameRegex = new RegExp(
      `^(?!.* {2})${restrictedSpecialCharacters}+(?: ${restrictedSpecialCharacters}+)*$`
    );

    const isValidHashtag = isValidLength && validUsernameRegex.test(username);

    return isValidHashtag;
  });
});
