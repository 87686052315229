import { User } from 'firebase/auth';
import { QuerySnapshot } from 'firebase/firestore';

import {
  IHashtagData,
  IPriceWithId,
  IProductWithId,
  IProfile,
  ISubscription,
  Nullable,
  TFiles,
} from 'types/interfaces';

export interface IInitialState {
  cachedPasswords: {
    [key: string]: string;
  };
  currentUser: Nullable<User>;
  fileQuerySnapshot: Nullable<QuerySnapshot>;
  files: TFiles;
  folderReference: Nullable<string>;
  folderUids: string[];
  hashtagData: Nullable<IHashtagData>;
  hasMore: boolean;
  hasActiveOrRenewableSubscription: boolean;
  isAuthLoading: boolean;
  isAuthModalOpened: boolean;
  isAuthorized: boolean;
  isSubscriptionLoading: boolean;
  profile: Nullable<IProfile>;
  subscription: Nullable<ISubscription>;
  subscriptionPlan: Nullable<IProductWithId>;
  subscriptionPrice: Nullable<IPriceWithId>;
}

export const initialState: IInitialState = {
  cachedPasswords: {},
  currentUser: null,
  fileQuerySnapshot: null,
  files: null,
  folderReference: null,
  folderUids: [],
  hashtagData: null,
  hasMore: true,
  hasActiveOrRenewableSubscription: false,
  isAuthLoading: true,
  isAuthModalOpened: false,
  isAuthorized: false,
  isSubscriptionLoading: false,
  profile: null,
  subscription: null,
  subscriptionPlan: null,
  subscriptionPrice: null,
};
