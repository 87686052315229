import { createContext } from 'react';

import { IInitialState, initialState } from 'utils/initialState';

export type TGlobalStatePatchGetter = (prevState: Partial<IInitialState>) => Partial<IInitialState>;
export type TGlobalStateContextValueSetter = (
  patch: Partial<IInitialState> | TGlobalStatePatchGetter
) => void;

export type TContextType = [IInitialState, TGlobalStateContextValueSetter];

const GlobalStateContext = createContext<TContextType>([initialState, () => {}]);

export default GlobalStateContext;
