import { differenceInYears, format, parse } from 'date-fns';

import { DIGITS_REGEX } from 'constants/common';
import { Timestamp } from 'firebase/firestore';

export const OUTPUT_DATE_FORMAT = 'yyyy-MM-dd';
export const DISPLAY_DATE_FORMAT = 'dd.MM.yyyy';

export const parseDate = (dateString: string) => parse(dateString, DISPLAY_DATE_FORMAT, new Date());

/**
 * @return string DD.MM.YYYY
 * @param dateString in YYYY-MM-DD format
 */
export const formatDateStringForDisplay = (dateString?: string): string => {
  if (!dateString?.length) return '';

  const parts = String(dateString).match(DIGITS_REGEX);

  if (!parts || parts?.length < 2) {
    return dateString;
  }

  return [parts[2], parts[1], parts[0]].join('.');
};

/**
 * Converts an input date string in `dd.MM.yyyy` format
 * to the `yyyy-MM-dd` form suitable for saving through the API
 *
 * @param dateString - date string in the `dd.MM.yyyy` format
 * @returns date string in the `yyyy-MM-dd` format
 */
export const formatDateStringForSaving = (dateString: string): string => {
  const parsedDate = parse(dateString, DISPLAY_DATE_FORMAT, new Date());

  const dateStringSuitableForSaving = format(parsedDate, OUTPUT_DATE_FORMAT);

  return dateStringSuitableForSaving;
};

/**
 * Converts a date object
 * to the string of the `yyyy-MM-dd` form suitable for saving via the API
 *
 * @param {date} date object
 * @returns date string in the `yyyy-MM-dd` format
 */
export const formatDateForSaving = (date?: Date): string | undefined => {
  if (!date) return;

  const dateStringSuitableForSaving = format(date, OUTPUT_DATE_FORMAT);

  return dateStringSuitableForSaving;
};

/**
 * Returns the number of full years between two dates
 * @param {string} startDate the beginning date string
 * @param {string} endDate the end date string
 * @returns {number} number of full years between two dates
 */
export const getFullYearsBetween = (startDate: string, endDate: string): number => {
  return differenceInYears(new Date(endDate), new Date(startDate));
};

export const getTodaysDate = () => new Date();

/**
 *
 * @returns number of seconds passed since the Unix epoch
 */
export const getUnixTimestamp = () => Math.floor(Date.now() / 1000);

/**
 * We need to use this function instead of firebase's Timestamp.now()
 * because of the conflicts between the iOS and the web app
 *
 * @returns an object shaped similarly to firebase's Timestamp but without methods and
 * has its nanoseconds field always set to 0
 */
export const getFirebaseLikeTimestamp = () => {
  const timestamp = Timestamp.now();

  return {
    seconds: timestamp.seconds,
    nanoseconds: timestamp.nanoseconds,
  };
};

export const getFirebaseTimestamp = () => Timestamp.now();
