import { EXPERIMENTS_LOCALSTORAGE_KEY } from 'constants/common';
import { useEffect, useRef } from 'react';
import { useToggle } from 'react-use';

import consoleLogDev from 'utils/consoleLogDev';

const useABExperiment = (experimentKey: string) => {
  const selectedExperimentVariantRef = useRef(null);
  const [isLoading, setLoadingState] = useToggle(window.dataLayer ? true : false);

  useEffect(() => {
    const stopLoading = () => {
      setLoadingState(false);
    };

    if (window.dataLayer?.some((event: any) => event.event === 'gtm.dom')) {
      stopLoading();
    }

    document.body.addEventListener('gtm.dom', stopLoading);

    return () => {
      document.body.removeEventListener('gtm.dom', stopLoading);
    };
  }, [setLoadingState]);

  if (experimentKey) {
    try {
      const experimentsDataJSON = localStorage.getItem(EXPERIMENTS_LOCALSTORAGE_KEY);
      const experimentsData = (experimentsDataJSON && JSON.parse(experimentsDataJSON)) || {};

      selectedExperimentVariantRef.current = experimentsData[experimentKey];
    } catch (error) {
      consoleLogDev(error);
    }
  }

  return {
    variant: selectedExperimentVariantRef.current,
    isVariantLoading: isLoading,
  };
};

export default useABExperiment;
