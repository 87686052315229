import { PropsWithChildren } from 'react';
import cn from 'classnames';

// import { useTranslation } from 'react-i18next';

import { Nullable } from 'types/interfaces';

import styles from './PageProgressBar.module.scss';

interface IProps {
  isVisible: boolean;
  progress: Nullable<Number>;
}

const PageProgressBar: React.FC<PropsWithChildren<IProps>> = props => {
  const { isVisible, progress } = props;

  return (
    <>
      <div
        className={cn(styles.progress, {
          [styles.progressVisible]: isVisible,
        })}
      >
        <div className={cn(styles.progressBar)} style={{ width: `${progress || 0}%` }}></div>
      </div>
    </>
  );
};

export default PageProgressBar;
