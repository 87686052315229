'use client';
import { PRICING_PAGE_ROUTE } from 'constants/routes';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { HttpsCallableResult, getFunctions, httpsCallable } from 'firebase/functions';

import app from 'utils/firebase';
import { db } from 'utils/firestore';
import consoleLogDev, { consoleErrorDev } from 'utils/consoleLogDev';

import {
  ICustomerPortalSessionParams,
  ICustomerPortalSessionResponse,
  IPrice,
  IProduct,
  ISubscription,
} from 'types/interfaces';
import {
  CREATE_PORTAL_LINK_CLOUD_FUNCTION_NAME,
  FIREBASE_APP_CLOUD_FUNCTIONS_REGION,
} from 'constants/common';

export const getCheckoutUrl = async (priceId: string): Promise<string> => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error('User is not authenticated');

  const db = getFirestore(app);
  const checkoutSessionRef = collection(db, 'customers', userId, 'checkout_sessions');

  const docRef = await addDoc(checkoutSessionRef, {
    price: priceId,
    success_url: `${window.location.origin}${PRICING_PAGE_ROUTE}`,
    cancel_url: `${window.location.origin}${PRICING_PAGE_ROUTE}`,
    // TODO: Remove if unnecessary and disable them in the customer portal settings
    allow_promotion_codes: true,
  });

  return new Promise<string>((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, snap => {
      const { error, url } = snap.data() as {
        error?: { message: string };
        url?: string;
      };
      if (error) {
        unsubscribe();
        reject(new Error(`An error occurred: ${error.message}`));
      }
      if (url) {
        consoleLogDev('Stripe Checkout URL:', url);
        unsubscribe();
        resolve(url);
      }
    });
  });
};

export const getCustomerPortalUrl = async (
  params: ICustomerPortalSessionParams = {}
): Promise<string> => {
  const { returnUrl = window.location.href, locale = 'auto', configuration, flow_data } = params;

  let response: HttpsCallableResult<ICustomerPortalSessionResponse>;

  try {
    const functions = getFunctions(app, FIREBASE_APP_CLOUD_FUNCTIONS_REGION);
    const functionRef = httpsCallable<ICustomerPortalSessionParams, ICustomerPortalSessionResponse>(
      functions,
      CREATE_PORTAL_LINK_CLOUD_FUNCTION_NAME
    );

    response = await functionRef({
      returnUrl,
      locale,
      configuration,
      flow_data,
    });

    consoleLogDev('Stripe customer portal URL: ', response.data.url);
  } catch (error) {
    consoleErrorDev(error);
  }

  return new Promise<string>((resolve, reject) => {
    if (response.data.url) {
      resolve(response.data.url);
    } else {
      reject(new Error('No url returned'));
    }
  });
};

export const getSubscriptions = async () => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error('User not logged in');

  const db = getFirestore(app);
  const subscriptionsRef = collection(db, 'customers', userId, 'subscriptions');

  const q = query(subscriptionsRef);

  return new Promise<ISubscription[]>((resolve, reject) => {
    const unsubscribe = onSnapshot(
      q,
      snapshot => {
        const subscsriptionsData = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as ISubscription[];

        if (subscsriptionsData) {
          consoleLogDev('Subscriptions found');
          resolve(subscsriptionsData);
        } else {
          consoleLogDev('No subscriptions found');
          resolve([]);
        }
        unsubscribe();
      },
      reject
    );
  });
};

export const getProductsAndPrices = async () => {
  try {
    const productsRef = collection(db, 'products');
    const productsQuery = query(productsRef, where('active', '==', true));
    const productsSnapshot = await getDocs(productsQuery);

    const productsWithPrices = [];
    for (const productDoc of productsSnapshot.docs) {
      const pricesRef = collection(productDoc.ref, 'prices');
      const pricesQuery = query(pricesRef, where('active', '==', true));
      const pricesSnapshot = await getDocs(pricesQuery);

      const product = productDoc.data() as IProduct;
      const prices: { [key: string]: IPrice } = {};

      pricesSnapshot.docs.forEach(priceDoc => {
        const priceId = priceDoc.id;
        const priceData = priceDoc.data() as IPrice;
        prices[priceId] = priceData;
      });

      productsWithPrices.push({ ...product, productId: productDoc.id, prices });
    }

    return productsWithPrices;
  } catch (error) {
    consoleErrorDev('Error fetching products and prices: ', error);

    return [];
  }
};
