import { useTranslation } from 'react-i18next';

import LinkButton from 'components/LinkButton';
import { ReactComponent as IconArrowRight } from 'images/icons/arrowRight.svg';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>{t('notFoundPageTitle')}</h1>
        <h3 className={styles.subtitle}>{t('notFoundPageSubtitle')}</h3>

        <LinkButton
          outerClassName={styles.button}
          to={'/'}
          type="primary"
          iconRight={<IconArrowRight />}
        >
          {t('notFoundPageBtnText')}
        </LinkButton>
      </div>
    </div>
  );
};

export default NotFound;
