import { S3Client } from '@aws-sdk/client-s3';
import { createContext } from 'react';

import { Nullable } from 'types/interfaces';

type TContextType = {
  s3Client: Nullable<S3Client>;
  setS3Client: (S3Client: Nullable<S3Client>) => void;
  isS3ClientLoading: boolean;
  setS3ClientLoadingState: (state: boolean) => void;
};

const s3Context = createContext<TContextType>({
  s3Client: null,
  setS3Client: () => {},
  isS3ClientLoading: false,
  setS3ClientLoadingState: () => {},
});

export default s3Context;
