import { FileRejection, useDropzone } from 'react-dropzone';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './PageDropzone.module.scss';

interface IProps {
  children?: ReactNode;
  disabled?: boolean;
  onDropAccepted?: (acceptedFiles: File[]) => void;
  onDropRejected?: (rejectedFiles: FileRejection[]) => void;
}

const PageDropzone = (props: IProps) => {
  const { t } = useTranslation();

  const eventCounterRef = useRef(0);
  const [isDragging, setDraggingState] = useState(false);

  const { children, disabled, onDropAccepted } = props;

  useEffect(() => {
    const handleDragEnter = () => {
      eventCounterRef.current += 1;

      if (eventCounterRef.current === 1) {
        setDraggingState(true);
      }
    };

    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();

      eventCounterRef.current -= 1;

      if (eventCounterRef.current === 0) {
        setDraggingState(false);
      }
    };

    document.body.addEventListener('dragenter', handleDragEnter);
    document.body.addEventListener('dragleave', handleDragLeave);
    document.body.addEventListener('drop', handleDragLeave);

    return () => {
      document.body.removeEventListener('dragenter', handleDragEnter);
      document.body.removeEventListener('dragleave', handleDragLeave);
      document.body.removeEventListener('drop', handleDragLeave);
    };
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    disabled,
    multiple: true,
    onDropAccepted,
  });

  return (
    <>
      <div
        {...getRootProps({
          className: cn(styles.dropzone, { [styles.dropzoneActive]: isDragActive }),
        })}
      >
        <input {...getInputProps()} />

        {children}

        <div
          className={cn(styles.overlay, {
            [styles.overlayVisible]: !disabled && isDragging,
          })}
        >
          <div className={styles.overlayContent}>{t('pageDropzoneText')}</div>
        </div>
      </div>
    </>
  );
};

export default PageDropzone;
