/**
 * Concatenates pathname parts into a single string.
 * Ensures that parts will be joined by a single slash
 *
 * @param {string[]} paths - path pieces
 * @returns {string} joined pathname
 */
export const concatPaths = (...paths: (string | null | undefined)[]): string => {
  // skips repeating slashes in protocols
  const repeatingSlashesRegex = /([^:]|^)\/{2,}/g;

  return paths.filter(Boolean).join('/').replace(repeatingSlashesRegex, '$1/');
};
