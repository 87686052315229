import { Link } from 'react-router-dom';
import cn from 'classnames';

import { concatPaths } from 'utils/urls';

import styles from './Breadcrumbs.module.scss';

interface IProps {
  folderNames?: string[];
  hashtag?: string;
}

const Breadcrumbs = (props: IProps) => {
  const { folderNames, hashtag } = props;

  const breadcrumbs = folderNames?.map((folderName, folderNameIndex) => {
    const prevFolderNames = folderNames.slice(0, folderNameIndex).filter(Boolean);
    const prevPath = prevFolderNames.join('/');

    const isLastFolderUid = folderNameIndex === folderNames.length - 1;
    const isFirstFolderUid = folderNameIndex === 0;

    const label = <>{folderName}</>;

    return (
      <span
        key={folderName + folderNameIndex}
        className={cn(styles.breadcrumb, { [styles.breadcrumbLast]: isLastFolderUid })}
      >
        <span
          className={cn(styles.breadcrumbsDelimiter, {
            [styles.hashtagBreadcrumbsDelimiter]: isFirstFolderUid,
          })}
        >
          /
        </span>

        {isLastFolderUid ? (
          <span className={styles.breadcrumbText}>{label}</span>
        ) : (
          <Link
            className={styles.breadcrumbText}
            to={'/' + concatPaths(hashtag, prevPath, folderName)}
          >
            {label}
          </Link>
        )}
      </span>
    );
  });

  return <>{breadcrumbs}</>;
};

export default Breadcrumbs;
