import { Fragment, PropsWithChildren } from 'react';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import AnimatedEntrance from 'components/AnimatedEntrance';
import ContactUs from 'components/ContactUs';
import Footer from 'components/Footer';

import heroImage from 'images/infoPage/1.jpg';
import section1Image from 'images/infoPage/2.png';
import section2Image from 'images/infoPage/3.png';
import section3Image from 'images/infoPage/4.png';

import { ReactComponent as IconLock } from 'images/icons/lock.svg';
import { ReactComponent as IconShare } from 'images/icons/share.svg';
import { ReactComponent as IconStorage } from 'images/icons/storage.svg';

import styles from './Info.module.scss';

interface IProps {}

const Info: React.FC<PropsWithChildren<IProps>> = _props => {
  const { t } = useTranslation();

  const sections = [
    {
      title: t('infoPageSection1Title'),
      text: t('infoPageSection1Content'),
      image: section1Image,
    },
    {
      title: t('infoPageSection2Title'),
      text: t('infoPageSection2Content'),
      image: section2Image,
    },
    {
      title: t('infoPageSection3Title'),
      text: t('infoPageSection3Content'),
      image: section3Image,
    },
  ];

  const features = [
    {
      title: t('infoPageFeature1Title'),
      text: t('infoPageFeature1Content'),
      icon: <IconShare className={styles.featureIcon} />,
    },
    {
      title: t('infoPageFeature2Title'),
      text: t('infoPageFeature2Content'),
      icon: <IconLock className={styles.featureIcon} />,
    },
    {
      title: t('infoPageFeature3Title'),
      text: t('infoPageFeature3Content'),
      icon: <IconStorage className={styles.featureIcon} />,
    },
  ];

  const renderHr = (props?: JSX.IntrinsicElements['div']) => {
    return (
      <div className={styles.hrContainer} {...props}>
        <hr className={styles.hr} />
      </div>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <AnimatedEntrance<HTMLDivElement>>
          {(ref, className) => (
            <div className={cn(styles.hero, className)} ref={ref}>
              <div className={styles.heroMainContent}>
                <h1 className={styles.heroTitle}>
                  <Trans i18nKey="infoPageHeroTitle" />
                </h1>
                <div className={styles.heroImageContainer}>
                  <img src={heroImage} alt="Hero" className={styles.heroImage} />
                </div>
              </div>

              <div className={cn(styles.heroFootnote, styles.narrowContainer)}>
                <div className={styles.heroFootnoteInner}>{t('infoPageHeroFootnote')}</div>
              </div>
            </div>
          )}
        </AnimatedEntrance>

        {sections.map((section, sectionIndex) => {
          const isLastSection = sectionIndex + 1 === sections.length;
          const isFirstSection = sectionIndex === 0;

          return (
            <Fragment key={sectionIndex}>
              <div
                className={cn(styles.section, {
                  [styles.sectionLast]: isLastSection,
                  [styles.sectionFirst]: isFirstSection,
                })}
              >
                <AnimatedEntrance<HTMLDivElement>>
                  {(ref, className) => (
                    <div ref={ref} className={cn(styles.sectionImageContainer, className)}>
                      <img
                        src={section.image}
                        alt={section.title}
                        className={styles.sectionImage}
                      />
                    </div>
                  )}
                </AnimatedEntrance>

                <div className={styles.sectionContent}>
                  <div className={styles.sectionContentInner}>
                    <AnimatedEntrance<HTMLDivElement>>
                      {(ref, className) => (
                        <h3 className={cn(styles.sectionTitle, className)} ref={ref}>
                          {sectionIndex + 1}. {section.title}
                        </h3>
                      )}
                    </AnimatedEntrance>

                    <AnimatedEntrance<HTMLDivElement>>
                      {(ref, className) => (
                        <div ref={ref} className={cn(styles.sectionText, className)}>
                          {section.text}
                        </div>
                      )}
                    </AnimatedEntrance>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}

        {renderHr({ style: {} })}

        <AnimatedEntrance<HTMLDivElement>>
          {(ref, className) => (
            <div ref={ref} className={cn(styles.features, styles.narrowContainer, className)}>
              {features.map((feature, featureIndex) => {
                return (
                  <div className={styles.feature} key={featureIndex}>
                    {
                      /* feature.icon && */ <div className={styles.featureIconWrapper}>
                        {feature.icon}
                      </div>
                    }
                    <h4 className={styles.featureTitle}>{feature.title}</h4>
                    <div className={styles.featureText}>{feature.text}</div>
                  </div>
                );
              })}
            </div>
          )}
        </AnimatedEntrance>

        {renderHr({ style: {} })}

        <AnimatedEntrance<HTMLDivElement>>
          {(ref, className) => (
            <div
              ref={ref}
              className={cn(styles.narrowContainer, styles.contactUsContainer, className)}
            >
              <ContactUs titleClassName={styles.contactUsTitle} />
            </div>
          )}
        </AnimatedEntrance>
      </div>

      <Footer />
    </>
  );
};

export default Info;
