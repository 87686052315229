import { Nullable } from 'types/interfaces';

export const observeElementRemoval = (trackedElementSelector: string, callback: () => void) => {
  let observer: Nullable<MutationObserver> = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      if (mutation.removedNodes.length > 0) {
        const removedNodesArray = Array.from(mutation.removedNodes);

        const trackedNode = removedNodesArray.find(node => {
          const isElement = node.nodeType === 1;

          if (!isElement) return false;

          const element = node as HTMLElement;

          if (
            element.matches(trackedElementSelector) ||
            element.contains(element.querySelector(trackedElementSelector))
          ) {
            return node;
          }

          return false;
        });

        if (trackedNode) {
          callback();

          observer?.disconnect?.();
          observer = null;
        }
      }
    }
  });

  observer.observe(document, {
    childList: true,
    subtree: true,
  });
};

export default observeElementRemoval;
