import Spinner from 'components/Spinner';

import styles from './PagePreloader.module.scss';

const PagePreloader = () => {
  return (
    <>
      <div className={styles.spinnerContainer}>
        <Spinner className={styles.spinner}></Spinner>
      </div>
    </>
  );
};

export default PagePreloader;
