import { fromWebToken } from '@aws-sdk/credential-providers';
import {
  S3Client,
  HeadBucketCommand,
  HeadObjectCommand,
  GetObjectCommand,
} from '@aws-sdk/client-s3';
import { useCallback, useContext } from 'react';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

import { AWS_BUCKET_NAME, AWS_REGION, AWS_UPLOADER_ROLE_ARN } from 'constants/common';

import { triggerAhrefDownload } from 'utils/files';
import consoleLogDev, { consoleErrorDev } from 'utils/consoleLogDev';
import s3Context from 'utils/S3Context';

import useGlobalState from 'hooks/useGlobalState';
import { IFile, Nullable } from 'types/interfaces';

let s3ClientStartedLoading = false;

const useS3 = () => {
  const { s3Client, setS3Client } = useContext(s3Context);
  const [globalState] = useGlobalState();
  const { currentUser, folderUids, hashtagData } = globalState;
  const { t } = useTranslation();

  useMount(async () => {
    if (!currentUser || s3Client || s3ClientStartedLoading) return;

    s3ClientStartedLoading = true;

    const credentialsProvider = async () => {
      const webIdentityToken = await currentUser.getIdToken();

      const getFromWebTokenCrendetialsProvider = fromWebToken({
        roleArn: AWS_UPLOADER_ROLE_ARN,
        roleSessionName: currentUser?.uid,
        webIdentityToken,
      });

      return getFromWebTokenCrendetialsProvider();
    };

    try {
      const newS3Client = new S3Client({
        credentials: credentialsProvider,
        region: AWS_REGION,
        requestHandler: new XhrHttpHandler({}),
      });

      const headBucketParams = {
        Bucket: AWS_BUCKET_NAME,
      };

      const headBucketCommand = new HeadBucketCommand(headBucketParams);

      await newS3Client.send(headBucketCommand);

      setS3Client(newS3Client);
    } catch (error) {
      s3ClientStartedLoading = false;

      if (error.name === 'NotFound') {
        consoleLogDev('Bucket does not exist.');
      } else {
        consoleLogDev('Error checking bucket existence:', error);
        // TODO check if credentials refresh is needed
      }
    }
  });

  const getFileS3ObjectKey = useCallback(
    (file: IFile, encodeFileName?: boolean, useFullPath?: boolean) => {
      if (!hashtagData) return null;

      const path = folderUids.join('/');
      const Key =
        `${file.ownerUid}/${hashtagData.name}/` +
        `${(useFullPath ? path : file.folderReference) || ''}/` +
        `${encodeFileName ? encodeURIComponent(file.name) : file.name}` +
        `${file.type ? `.${file.type}` : ''}`;

      return Key;
    },
    [hashtagData, folderUids]
  );

  const checkIfObjectExists = useCallback(
    async (Key: string) => {
      try {
        if (!s3Client) {
          throw new Error(t('failedConnectToCloud'), { cause: 'AWS' });
        }

        const headParams = {
          Bucket: AWS_BUCKET_NAME,
          Key,
        };

        await s3Client.send(new HeadObjectCommand(headParams));

        return true;
      } catch (error) {
        if (error.name === 'NotFound' || error.$metadata?.httpStatusCode === 404) {
          consoleLogDev('Object does not exist', Key);
          return false;
        } else {
          consoleErrorDev('Error occurred:', error);

          throw error;
        }
      }
    },
    [s3Client, t]
  );

  const getFileS3ObjectKeyIfExists = useCallback(
    async (file: IFile): Promise<Nullable<string>> => {
      const possibleArgsCombination = [
        [false, false],
        [true, false],
        [false, true],
        [true, true],
      ];

      let Key = null;
      for (let i = 0; i < possibleArgsCombination.length; i++) {
        const [arg1, arg2] = possibleArgsCombination[i];

        const currentKey = getFileS3ObjectKey(file, arg1, arg2);
        const doesObjectExist = currentKey ? await checkIfObjectExists(currentKey) : false;

        if (doesObjectExist) {
          Key = currentKey;
          break;
        }
      }

      return Key;
    },
    [checkIfObjectExists, getFileS3ObjectKey]
  );

  const triggerFileDownload = async (file: IFile) => {
    const Key = await getFileS3ObjectKeyIfExists(file);
    const filename = file.name + file.type;

    if (s3Client && Key) {
      const command = new GetObjectCommand({
        Bucket: AWS_BUCKET_NAME,
        Key,
        ResponseContentDisposition: 'attachment', // Set Content-Disposition to attachment
      });

      try {
        const presignedUrl = await getSignedUrl(s3Client, command, {
          expiresIn: 60,
        });

        triggerAhrefDownload(presignedUrl, filename);
      } catch (error) {
        consoleErrorDev('Error generating presigned URL:', error);
      }
    } else {
      triggerAhrefDownload(file.url, filename);
    }
  };

  return {
    s3Client,
    getFileS3ObjectKey,
    checkIfObjectExists,
    getFileS3ObjectKeyIfExists,
    triggerFileDownload,
  };
};

export default useS3;
