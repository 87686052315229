import { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Button from 'components/Button';

import { MAX_FILES_PER_UPLOAD } from 'constants/common';

import { ReactComponent as IconUpload } from 'images/icons/cloudUpload.svg';

import styles from './Dropzone.module.scss';
import useHandleDrop from 'hooks/useHandleDrop';

interface IProps {
  children?: ReactNode;
  className?: string;
  onDropAccepted: (files: File[]) => void;
}

const Dropzone = (props: IProps) => {
  const { t } = useTranslation();

  const { children, className, onDropAccepted } = props;

  const handleDropAccepted = useHandleDrop(onDropAccepted);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted: handleDropAccepted,
    noClick: true,
    noKeyboard: true,
    multiple: true,
  });

  const handleBrowseBtnClick = () => {
    open();
  };

  return (
    <>
      <div
        {...getRootProps({
          className: cn(styles.dropzone, className, { [styles.dropzoneActive]: isDragActive }),
        })}
      >
        <input {...getInputProps()} />

        {children}

        <div className={styles.content}>
          <IconUpload className={styles.icon}></IconUpload>
          <div className={styles.text}>{t('dropzoneText')}</div>
          <div className={styles.smallText}>{t('dropzoneSmallText', { MAX_FILES_PER_UPLOAD })}</div>

          <Button type="primary" className={styles.btn} onClick={handleBrowseBtnClick}>
            {t('dropzoneBtnText')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Dropzone;
