import { EXPERIMENTS_LOCALSTORAGE_KEY } from 'constants/common';
import consoleLogDev from './consoleLogDev';

export const sendMeasuredEvent = (experimentKey: string, eventAction: string) => {
  if (!experimentKey || !eventAction) return;

  try {
    const experimentsDataJSON = localStorage.getItem(EXPERIMENTS_LOCALSTORAGE_KEY);
    const experimentsData = (experimentsDataJSON && JSON.parse(experimentsDataJSON)) || {};

    window.dataLayer?.push({
      event: 'experiment_event',
      event_action: eventAction,
      experiment_name: experimentKey,
      experiment_variant: experimentsData[experimentKey],
    });
  } catch (error) {
    consoleLogDev(error);
  }
};

export const sendDataLayerEvent = (eventName: string, data?: Record<string, any>) => {
  window.dataLayer?.push({
    event: eventName,
    ...data,
  });
};
