import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import Button from 'components/Button';
import LinkButton from 'components/LinkButton';

import { PRICING_PAGE_ROUTE } from 'constants/routes';

import styles from './useUpgradePlan.module.scss';

const useUpgradePlan = () => {
  const { t } = useTranslation();

  const [isOpen, toggleOpenedState] = useToggle(false);

  const handleRequestClose = () => {
    toggleOpenedState(false);
  };

  const upgradePlanModal = (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      className={styles.modal}
      contentClassName={styles.contentClassName}
    >
      <div className={styles.content}>
        <div className={styles.iconContainer}></div>
        <h2 className={styles.title}>{t('outOfStorageTitle')}</h2>
        <div className={styles.description}>{t('outOfStorageText')}</div>
      </div>

      <div className={styles.btnContainer}>
        <Button type="secondary" onClick={handleRequestClose}>
          {t('cancel')}
        </Button>

        <LinkButton to={PRICING_PAGE_ROUTE} type="primary" className={styles.ctaBtn} openInNewTab>
          <span className={styles.btnText}>{t('checkPricingPlans')}</span>
          <span className={styles.btnEmoji}>🚀</span>
        </LinkButton>
      </div>
    </Modal>
  );

  return {
    upgradePlanModal,
    openUpgradeModal: () => toggleOpenedState(true),
    closeUpgradeModal: () => toggleOpenedState(false),
  };
};

export default useUpgradePlan;
