import { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Button from 'components/Button';

import styles from './ConfirmationModal.module.scss';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  title: ReactNode;
  isLoading?: boolean;
}

const ConfirmationModal: React.FC<PropsWithChildren<IProps>> = props => {
  const { isOpen, onRequestClose, title, onCancel, onConfirm, isLoading } = props;

  const { t } = useTranslation();

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className={styles.modal}
        contentClassName={styles.modalContent}
        showCloseButton
      >
        <h2 className={styles.title}>{title}</h2>

        <div className={styles.btnContainer}>
          <Button
            type="secondary"
            className={cn(styles.btn, styles.cancelBtn)}
            onClick={onCancel}
            disabled={isLoading}
          >
            {t('no')}
          </Button>
          <Button
            type="primary"
            className={cn(styles.btn, styles.submitBtn)}
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {t('yes')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
