import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { PropsWithChildren } from 'react';
import { useSelection } from '@viselect/react';
import { useMedia, useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

import { ReactComponent as IconCross } from 'images/icons/cross2.svg';
import { ReactComponent as IconDownload } from 'images/icons/download.svg';
import { ReactComponent as IconTrash } from 'images/icons/trash2.svg';
import { ReactComponent as IconOpen } from 'images/icons/openInNewTab.svg';

import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';

import useGlobalState from 'hooks/useGlobalState';
import useS3 from 'hooks/useS3';

import { IFile } from 'types/interfaces';

import { db } from 'utils/firestore';
import { showDefaultErrorNotification, showSuccessfulNotification } from 'utils/notifications';
import { transformS3UrlIfNeeded } from 'utils/aws';
import { triggerAhrefDownload } from 'utils/files';

import styles from './FileActionsBar.module.scss';

interface IProps {
  selectedFiles: IFile[];
  selectedFileUids: Set<string>;
}

const FileActionsBar: React.FC<PropsWithChildren<IProps>> = props => {
  const { selectedFileUids, selectedFiles } = props;

  const [globalState, setGlobalState] = useGlobalState();
  const { profile, currentUser } = globalState;
  const selection = useSelection();
  const { t } = useTranslation();
  const { triggerFileDownload } = useS3();

  const [isDeleteModalOpened, toggleDeleteModalState] = useToggle(false);
  const [areFilesDeleting, toggleDeletingState] = useToggle(false);

  const selectedOwnFiles = selectedFiles.filter(
    selectedFile => selectedFile.ownerUid === profile?.uid
  );

  const clearSelection = () => {
    selection?.clearSelection();
  };

  const removeFileFromUi = (deletingFile: IFile) => {
    setGlobalState(prevState => ({
      files: prevState.files?.filter(storedFiles => storedFiles.uid !== deletingFile.uid),
    }));
  };

  const deleteFileFromFirestore = async (file: IFile) => {
    const docRef = doc(db, 'items', file.uid);

    await deleteDoc(docRef);
  };

  const updateFileOwnersUsedStorage = async (file: IFile) => {
    if (!profile) return;

    setGlobalState(prevState => {
      if (!prevState.profile) {
        return prevState;
      }

      const newUsedStorageInMB = prevState.profile.usedStorageInMB - file.sizeInMB;

      updateDoc(doc(db, 'profiles', file.ownerUid), { usedStorageInMB: newUsedStorageInMB });

      return {
        profile: {
          ...prevState.profile,
          usedStorageInMB: prevState.profile.usedStorageInMB - file.sizeInMB,
        },
      };
    });
  };

  const handleDeleteConfirmation = () => {
    try {
      toggleDeletingState(true);

      selectedOwnFiles.forEach(async selectedOwnFile => {
        removeFileFromUi(selectedOwnFile);
        await deleteFileFromFirestore(selectedOwnFile);
        await updateFileOwnersUsedStorage(selectedOwnFile);
      });

      selection?.clearSelection();
      toggleDeleteModalState();
      showSuccessfulNotification(t('filesWereSuccessfullyDeleted'));
    } catch (error) {
      showDefaultErrorNotification();
    } finally {
      toggleDeletingState(false);
    }
  };

  const handleOpenClick = () => {
    window.open(transformS3UrlIfNeeded(selectedFiles[0].url), '_blank');
  };

  const handleDownloadClick = () => {
    selectedFiles.forEach((selectedFile, selectedFileIndex) => {
      setTimeout(() => {
        if (currentUser) {
          triggerFileDownload(selectedFile);
        } else {
          triggerAhrefDownload(selectedFile.url, selectedFile.name + selectedFile.type);
        }
      }, selectedFileIndex * 1000);
    });
  };

  const selectionHasMixedOwners =
    Boolean(selectedFileUids.size) &&
    Boolean(selectedOwnFiles.length) &&
    selectedFileUids.size !== selectedOwnFiles.length;

  const isHorizontalSpaceLimited = useMedia('(max-width: 1023px)');

  return (
    <>
      <div
        className={cn(styles.container, { [styles.containerVisible]: selectedFileUids.size > 0 })}
      >
        <div className={styles.left}>
          <label className={cn(styles.checkbox)} title={t('fileActionsClearSelection')}>
            <input
              type="checkbox"
              className={styles.checkboxCheckbox}
              checked
              readOnly
              onClick={clearSelection}
            />

            <span className={styles.label}>
              <span className={styles.labelContent}></span>
            </span>
          </label>

          {isHorizontalSpaceLimited ? (
            <>
              {selectedFileUids.size > 0 && selectedFileUids.size}
              {selectionHasMixedOwners && `/${selectedOwnFiles.length}`}
            </>
          ) : (
            <>
              {selectedFileUids.size > 0 &&
                t('fileActionsBarNumFilesSelected', { count: selectedFileUids.size })}{' '}
              {selectionHasMixedOwners &&
                `(${t('fileActionsBarNumOwnFilesSelected', { count: selectedOwnFiles.length })})`}
            </>
          )}
        </div>

        <div className={styles.right}>
          <div className={cn(styles.actions, { [styles.mobileActions]: isMobile })}>
            {selectedFiles.length === 1 && (
              <Button
                type="flat"
                className={cn(styles.actionBtn, {
                  [styles.actionBtnCompact]: isHorizontalSpaceLimited,
                })}
                iconLeft={<IconOpen className={cn(styles.actionBtnIcon)} />}
                onClick={handleOpenClick}
                iconClassName={cn({ [styles.actionBtnIconAltered]: isHorizontalSpaceLimited })}
              >
                {!isHorizontalSpaceLimited && t('fileActionsBarOpen')}
              </Button>
            )}

            {selectedFiles.length > 0 && (
              <Button
                className={cn(styles.actionBtn, {
                  [styles.actionBtnCompact]: isHorizontalSpaceLimited,
                })}
                iconLeft={<IconDownload className={cn(styles.actionBtnIcon)} />}
                type="flat"
                onClick={handleDownloadClick}
                iconClassName={cn({ [styles.actionBtnIconAltered]: isHorizontalSpaceLimited })}
              >
                {isHorizontalSpaceLimited ? (
                  selectionHasMixedOwners && (
                    <div className={styles.actionBtnCount}>{selectedFiles.length}</div>
                  )
                ) : (
                  <>
                    {selectionHasMixedOwners
                      ? t('fileActionsBarDownloadNumFiles', { count: selectedFiles.length })
                      : t('fileActionsBarDownload')}
                  </>
                )}
              </Button>
            )}

            {selectedOwnFiles.length > 0 && (
              <Button
                className={cn(styles.actionBtn, {
                  [styles.actionBtnCompact]: isHorizontalSpaceLimited,
                })}
                iconLeft={<IconTrash className={cn(styles.actionBtnIcon, styles.trashIcon)} />}
                type="flat"
                onClick={toggleDeleteModalState}
                iconClassName={cn({ [styles.actionBtnIconAltered]: isHorizontalSpaceLimited })}
              >
                {isHorizontalSpaceLimited ? (
                  selectionHasMixedOwners && (
                    <div className={styles.actionBtnCount}>{selectedOwnFiles.length}</div>
                  )
                ) : (
                  <>
                    {selectionHasMixedOwners
                      ? t('fileActionsBarDeleteNumFiles', { count: selectedOwnFiles.length })
                      : t('fileActionsBarDelete')}
                  </>
                )}
              </Button>
            )}
          </div>
          <div
            className={styles.iconCloseContainer}
            onClick={clearSelection}
            title={t('fileActionsClearSelection')}
          >
            <IconCross className={styles.iconClose} />
          </div>
        </div>

        <ConfirmationModal
          isOpen={isDeleteModalOpened}
          onRequestClose={toggleDeleteModalState}
          title={
            selectedOwnFiles.length > 0 &&
            t('fileActionsBarFilesDeleteModalTitle', { count: selectedOwnFiles.length })
          }
          onCancel={toggleDeleteModalState}
          onConfirm={handleDeleteConfirmation}
          isLoading={areFilesDeleting}
        ></ConfirmationModal>
      </div>
    </>
  );
};

export default FileActionsBar;
