// import { useToggle } from 'react-use';
import cn from 'classnames';

import Input from 'components/Input';

import { ITextInputProps } from 'components/Input/Input';
// import { ReactComponent as IconEye } from 'images/icons/eyeOpened.svg';
// import { ReactComponent as IconEyeClosed } from 'images/icons/eyeClosed.svg';

import styles from './PasswordInput.module.scss';

interface IProps extends ITextInputProps {}

const PasswordInput = (props: IProps) => {
  const { className, inputClassName, ...restProps } = props;

  // const [isPasswordVisible, togglePasswordVisibility] = useToggle(false);

  return (
    <>
      <Input
        {...restProps}
        className={cn(styles.input, className)}
        inputClassName={cn(styles.inputInput, inputClassName)}
        type={'text'}
      ></Input>
    </>
  );
};

export default PasswordInput;
