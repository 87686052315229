/* eslint-disable no-console */
import { IS_PRODUCTION_ENV } from 'constants/common';

const consoleLogDev = (...args) => {
  if (!IS_PRODUCTION_ENV) {
    console.log(`[DEV LOG] [${new Date().toUTCString()}]`, ...args);
  }
};

export const consoleErrorDev = (...args) => {
  if (!IS_PRODUCTION_ENV) {
    console.error(...args);
  }
};

export default consoleLogDev;
