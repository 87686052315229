import { ReactNode } from 'react';
import cn from 'classnames';

import styles from './InputLabel.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
}

const InputLabel = (props: IProps) => {
  const { children, className } = props;

  return <div className={cn(styles.text, className)}>{children}</div>;
};

export default InputLabel;
