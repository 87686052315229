import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';

import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modal';
import { ReactComponent as IconLink } from 'images/icons/link.svg';

import useFetchFiles from 'hooks/useFetchFiles';
import { useProfile } from 'hooks/useProfile';

import consoleLogDev from 'utils/consoleLogDev';

import styles from './JoinHashtag.module.scss';

interface IProps {
  parentSelector?: () => HTMLElement;
  btnClassName?: string;
}

const schema = yup.object().shape({
  hashtag: yup.string().required(''),
});

type TFieldValues = yup.InferType<typeof schema>;

const JoinHashtag: React.FC<PropsWithChildren<IProps>> = props => {
  const { parentSelector, btnClassName } = props;

  const { t } = useTranslation();
  const { fetchHashtagData } = useFetchFiles();
  const { addHashtagToProfile } = useProfile();

  const [isModalOpened, toggleModalState] = useToggle(false);
  const [isLoading, toggleLoadingState] = useToggle(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
  } = useForm({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const closeAndResetModal = () => {
    toggleModalState();
    reset();
  };

  const onSubmit = async ({ hashtag }: TFieldValues) => {
    try {
      toggleLoadingState();

      const existingHashtagData = await fetchHashtagData(hashtag);

      if (existingHashtagData) {
        await addHashtagToProfile({ ...existingHashtagData, name: hashtag });

        toggleModalState();
      } else {
        setError('hashtag', {
          type: 'custom',
          message: t('joinHashtagDoesntExist'),
        });
      }
    } catch (error) {
      consoleLogDev(error);
    } finally {
      toggleLoadingState();
    }
  };

  const hasHashtagError = Boolean(errors.hashtag);

  return (
    <>
      <Button
        className={cn(styles.btn, styles.hashtagBtn, btnClassName)}
        type="flat"
        onClick={toggleModalState}
      >
        <IconLink className={styles.icon}></IconLink>
        <span className={styles.btnText}>{t('joinHashtag')}</span>
      </Button>

      <Modal
        parentSelector={parentSelector}
        isOpen={isModalOpened}
        onRequestClose={closeAndResetModal}
        showCloseButton
        className={styles.modal}
        htmlOpenClassName="modal-opened"
        onAfterClose={() => reset()}
      >
        <h2 className={styles.modalTitle}>{t('joinHashtagModalTitle')}</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            autoFocus
            placeholder={t('createHashtagInputPlaceholder')}
            inputClassName={styles.input}
            hasError={hasHashtagError}
            error={errors.hashtag?.type === 'custom' ? errors.hashtag?.message : undefined}
            {...register('hashtag', { max: 100 })}
          />

          <div className={styles.btnContainer}>
            <Button
              type="secondary"
              className={cn(styles.btn, styles.cancelBtn)}
              onClick={closeAndResetModal}
            >
              {t('cancel')}
            </Button>

            <Button
              type="primary"
              className={cn(styles.btn, styles.submitBtn)}
              htmlType="submit"
              disabled={hasHashtagError || !watch('hashtag')}
              isLoading={isLoading}
            >
              {t('submit')}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default JoinHashtag;
