import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import useGlobalState from 'hooks/useGlobalState';

import styles from './AuthModal.module.scss';
import SignInWithAppleButton from 'components/SignInWithAppleButton';
import SignInWithGoogleButton from 'components/SignInWithGoogleButton';

interface IProps {}

const AuthModal = (_props: IProps) => {
  const [globalState, setGlobalState] = useGlobalState();
  const { isAuthModalOpened } = globalState;

  const { t } = useTranslation();

  const handleRequestClose = () => {
    setGlobalState({ isAuthModalOpened: false });
  };

  return (
    <>
      <Modal
        isOpen={isAuthModalOpened}
        onRequestClose={handleRequestClose}
        className={styles.modal}
        contentClassName={styles.modalContent}
        showCloseButton
      >
        <h2 className={styles.title}>{t('authModalTitle')}</h2>

        <div className={styles.btnContainer}>
          <SignInWithAppleButton className={styles.btn} />
          <SignInWithGoogleButton className={styles.btn} />
        </div>
      </Modal>
    </>
  );
};

export default AuthModal;
