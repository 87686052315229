import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from 'react-use';

// This hook calculates custom size of the vh unit on mobile devices
// to prevent the following problem with the index page of the app:
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#css-custom-properties-the-trick-to-correct-sizing
const useSetVhSizeOnMobile = () => {
  const { height } = useWindowSize();

  useEffect(() => {
    if (isMobile) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--ha-vh', `${vh}px`);
    }
  }, [height]);
};

export default useSetVhSizeOnMobile;
