export const SCRIPT_SRC = (document.currentScript as HTMLScriptElement).src;
const SCRIPT_ORIGIN = SCRIPT_SRC && new URL(SCRIPT_SRC).origin;

export const IS_EMBEDDED =
  SCRIPT_ORIGIN.replace('www.', '') !== window.location.origin.replace('www.', '');
export const DEFAULT_ROOT_ELEMENT_ID = 'hashare-root';
export const DEFAULT_ROOT_ELEMENT_CLASSNAME = 'hashare-root';
export const BODY_EMBEDDED_CLASSNAME = 'hashare-embedded';
export const OFFLINE_STORE_NAME = 'hashare-store';

export const IS_PRODUCTION_ENV = /dev|localhost/.test(SCRIPT_ORIGIN) === false;

export const ROOT_ROUTE = '/';

export const DIGITS_REGEX = /\d+/g;
export const NON_DIGITS_REGEX = /[^0-9]/g;
export const PHONE_NUMBER_REGEX = /^\+?[0-9\s]*$/;

export const FILES_PER_FETCH_SIZE = 40;

export const AWS_REGION = 'eu-central-1';
export const AWS_BUCKET_NAME = 'hashare1';
export const AWS_UPLOADER_ROLE_ARN = 'arn:aws:iam::872389002823:role/firebase_role';
export const AWS_BUCKET_ORIGIN = `https://${AWS_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com`;
export const CLOUDFRONT_ORIGIN = 'https://storage.hash.cloud';

export const MAX_FILES_PER_UPLOAD = 500;
export const DEFAULT_PLAN_STORAGE_SIZE_IN_MB = 1000;

export const STRIPE_PRICING_TABLE_ID = 'prctbl_1PagSNRt2jeank74BJmgggWk';

export const FIREBASE_APP_CLOUD_FUNCTIONS_REGION = 'us-central1';
export const CREATE_PORTAL_LINK_CLOUD_FUNCTION_NAME =
  'ext-firestore-stripe-payments-createPortalLink';

export const EXPERIMENTS_LOCALSTORAGE_KEY = 'ha_experiments';
