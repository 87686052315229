import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { useCallback } from 'react';

import { IHashtagData } from 'types/interfaces';

import consoleLogDev from 'utils/consoleLogDev';
import { db } from 'utils/firestore';

import useGlobalState from 'hooks/useGlobalState';

export const useProfile = () => {
  const [globalState, setGlobalState] = useGlobalState();
  const { profile } = globalState;

  const addHashtagToProfile = useCallback(
    async (hashtagData: IHashtagData) => {
      if (!profile || !hashtagData) {
        return;
      }

      if (!(hashtagData.createdAt instanceof Timestamp)) {
        hashtagData.createdAt = new Timestamp(
          hashtagData.createdAt.seconds,
          hashtagData.createdAt.nanoseconds
        );
      }

      const prevHashtags = profile.hashtags || [];
      const prevHashtagsFiltered = prevHashtags.filter(
        prevHashtagData => prevHashtagData.name.toLowerCase() !== hashtagData.name.toLowerCase()
      );

      // Hashtag is already in a profile
      if (prevHashtags.length !== prevHashtagsFiltered.length) {
        return;
      }

      const hashtags = [hashtagData, ...prevHashtags];

      const updatedProfileData = {
        ...profile,
        hashtags,
      };

      try {
        const profileDocRef = doc(db, 'profiles', profile?.uid);

        await updateDoc(profileDocRef, { hashtags });

        setGlobalState({ profile: updatedProfileData });
      } catch (error) {
        consoleLogDev(error);
      }
    },
    [profile, setGlobalState]
  );

  return {
    addHashtagToProfile,
  };
};
