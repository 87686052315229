import { DEFAULT_ROOT_ELEMENT_CLASSNAME, DEFAULT_ROOT_ELEMENT_ID } from 'constants/common';

const getRootElement = (rootElementId: string) => {
  let rootElement = rootElementId && document.getElementById(rootElementId);

  if (!rootElement) {
    rootElement = document.createElement('div');

    rootElement.id = DEFAULT_ROOT_ELEMENT_ID;

    document.body.appendChild(rootElement);
  }

  rootElement.classList.add(DEFAULT_ROOT_ELEMENT_CLASSNAME);

  return rootElement;
};

export default getRootElement;
