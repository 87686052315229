const fr = {
  hey: 'salut,',
  you: 'toi !',
  nofiles: 'Aucun fichier trouvé pour ce',
  folder: ' dossier.',
  hashtag: ' hashtag.',
  uploadfiles: 'Télécharger des fichiers',
  or: 'ou',
  back: 'Retour',
  searchAnotherHashtag: 'Chercher un autre #hashtag',
  passwordEnterTitle: 'Ce #hashtag est protégé.',
  passwordEnterPromt: 'Veuillez entrer le mot de passe pour continuer.',
  password: 'Mot de passe :',
  cancel: 'Annuler',
  submit: 'OK',
  search: 'Rechercher',
  hashtagDoesntExist: "Ce #hashtag n'existe pas.",
  folderDoesntExist: "Ce dossier n'existe pas.",
  createHashtag: 'Créer un hashtag',
  checkHashtag: '#hashtag',
  instructions: 'Instructions :',
  downloadApp: "1. Téléchargez l'application et créez un #hashtag.",
  createAndUpload: '2. Téléchargez des fichiers en utilisant ce #hashtag.',
  typeIn: "3. Consultez tous les fichiers ici ou dans l'application.",
  nameIsAlreadyTaken: 'Ce nom est déjà pris. Veuillez en choisir un autre.',
  fileType: 'Fichier',
  folderType: 'Dossier',
  fileWasSuccessfullyRenamed: '{{type}} a été renommé avec succès',
  fileWasSuccessfullyDeleted: 'Le fichier a été supprimé avec succès',
  failedConnectToCloud:
    "Nous n'avons pas pu nous connecter au cloud. Veuillez recharger la page et réessayer ou contacter notre équipe de support.",
  authModalTitle: 'Veuillez choisir une option pour continuer',
  signIn: 'Se connecter',
  signInWithApple: 'Se connecter avec Apple',
  signInWithGoogle: 'Se connecter avec Google',
  createFolderInputPlaceholder: 'Nom du dossier',
  createFolderModalTitle: 'Veuillez entrer un nom de dossier',
  hashtagAlreadyExists:
    'Le hashtag donné a déjà été créé. Veuillez choisir un autre nom de hashtag',
  createHashtagModalTitle: 'Pensez à un nouveau hashtag',
  createHashtagRequirement1: 'Minimum 6 caractères',
  createHashtagRequirement2: 'Minimum 1 chiffre',
  createHashtagRequirement3: "Pas d'espaces ni de symboles spéciaux",
  createHashtagInputPlaceholder: '#hashtag',
  dropzoneText: 'Glissez-déposez vos fichiers ici',
  dropzoneSmallText: 'Maximum {{MAX_FILES_PER_UPLOAD}} fichiers',
  dropzoneBtnText: ' Parcourir les fichiers',
  fileMoreActionsTitle: "Plus d'actions",
  fileActionDelete: 'Supprimer',
  fileActionEdit: 'Modifier',
  fileRenameModalTitle: 'Renommer',
  navbarWebAppItemTitle: 'web app',
  navbariOSAppItemTitle: 'iOS app',
  navbarInfoItemTitle: 'info',
  navbarPricingItemTitle: 'tarification',
  navbarHelpItemTitle: 'aide',
  navbarImprintItemTitle: 'mentions légales',
  notFoundPageTitle: 'La page que vous avez demandée est introuvable !',
  notFoundPageSubtitle: "Consultez notre page d'accueil à la place",
  notFoundPageBtnText: "Aller à la page d'accueil",
  pageDropzoneText: 'Glissez-déposez vos fichiers ici',
  fileUploaded: 'Fichier téléchargé avec succès !',
  filesUploaded: 'Fichiers téléchargés avec succès !',
  fileUploadModalSelectedFiles: 'Fichiers sélectionnés',
  fileUploadModalClearFiles: 'Effacer les fichiers',
  defaultErrorMessage:
    "Quelque chose s'est mal passé. Veuillez réessayer plus tard ou contacter notre équipe de support.",
  addFolder: 'Ajouter un dossier',
  addFiles: 'Ajouter des fichiers',
  profileUsername: "Nom d'utilisateur",
  profileUsedStorage: 'Stockage utilisé',
  profileOutOf: 'sur',
  profileSignOut: 'Déconnexion',
  profileSupport: 'Assistance',
  outOfStorageTitle: 'Espace insuffisant !',
  outOfStorageText:
    "Vous n'avez pas assez d'espace de stockage disponible pour télécharger ce fichier. Vous pouvez l'étendre en passant à un plan de stockage différent.",
  checkPricingPlans: 'Vérifiez nos plans',
  noFilesFound: 'Aucun fichier trouvé.',
  setHashtagPasswordModalTitle: 'Veuillez entrer un mot de passe pour votre hashtag',
  protectWithPassword: 'Protéger le hashtag avec un mot de passe',
  removePasswordProtection: 'Supprimer la protection par mot de passe',
  hashtagPasswordUpdated: 'Le mot de passe a été défini avec succès',
  hashtagPasswordReset: 'Le mot de passe a été supprimé avec succès',
  settings: 'Paramètres',
  buyStorage: 'Acheter du stockage',
  deleteAccount: 'Supprimer le compte',
  usernameChangeModalTitle: 'Votre nom d’utilisateur sera lié aux fichiers que vous téléchargez',
  profileAccount: 'Compte',
  manageSubscription: 'Gérer l’abonnement',
  subscriptionSuccessMessage:
    'Abonnement réussi ! Votre stockage a été mis à jour selon le plan sélectionné.',
  pricingPageTitle: '1000 Go avec <span>hashcloud+</span>',
  pricingPageSubtitle: 'Abonnement mensuel, résiliable à tout moment',
  pricingPageAlreadySubscribed_one:
    'Vous ne pouvez pas vous abonner à ce plan car votre compte est déjà associé à un abonnement actif.',
  pricingPageAlreadySubscribed_other:
    'Vous ne pouvez pas vous abonner à l’un de ces plans car votre compte est déjà associé à un abonnement actif.',
  pricingFaqTitle: 'Questions fréquemment posées',
  pricingFaqQuestion1: "Comment puis-je acheter plus d'espace de stockage ?",
  pricingFaqAnswer1:
    "Pour télécharger des fichiers sur hashcloud, inscrivez-vous en utilisant l'une des méthodes disponibles. Vous pouvez télécharger jusqu'à 1 Go gratuitement. Pour plus de stockage, vous pouvez passer à 1000 Go pour seulement 4,99 € par mois.",
  pricingFaqQuestion2: 'Comment fonctionne le paiement ?',
  pricingFaqAnswer2:
    "Les paiements sont gérés de manière sécurisée via Stripe en utilisant votre méthode de paiement préférée. Vos informations sont protégées et privées ; l'application n'y a pas accès.",
  pricingFaqQuestion3: "Comment l'espace de stockage utilisé est-il calculé ?",
  pricingFaqAnswer3:
    "Hashcloud ne facture que les fichiers que vous avez téléchargés. La visualisation, le téléchargement, la modification ou la suppression ne sont pas facturés. Exemple : si vous avez téléchargé un fichier de 1 Mo dans chacun de 10 hashtags différents, vous avez utilisé 10 Mo d'espace de stockage.",
  contactUsTitle: 'Vous avez des questions?',
  contactUsText: 'Envoyez-nous un e-mail et nous vous répondrons dès que possible.',
  contactUsBtnText: 'Nous contacter',
  subscribe: "S'abonner",
  selected: '✅ Sélectionné',
  active: '✅ Actif',
  select: 'Sélectionner',
  per: 'par',
  month: 'mois',
  year: 'an',
  currentPlan: 'Plan actuel',
  mostPopular: 'Le plus populaire',
  subscriptionInactiveMessage:
    "Votre abonnement n'est plus actif. Votre espace de stockage disponible a été réinitialisé à 1 Go.",
  monthly: 'Mensuel',
  yearly: 'Annuel',
  standardPlanName: 'Plan Standard',
  standardPlanDescription: '10 Go de stockage inclus',
  plusPlanName: 'Plan Plus',
  plusPlanDescription: '100 Go de stockage inclus',
  ultraPlanName: 'Plan Ultra',
  ultraPlanDescription: '1000 Go de stockage inclus',
  close: 'Fermer',
  company: 'Entreprise',
  address: 'Adresse de la direction',
  contacts: 'Contacts',
  representedBy: 'Représenté par',
  registryEntry: 'Inscription au registre',
  vatId:
    "Numéro d'identification de la taxe sur la valeur ajoutée conformément à l'article §27a de la loi sur la taxe sur la valeur ajoutée",
  contentResponsible: 'Responsable du contenu selon § 55 Abs. 2 RStV',
  disputeResolution: 'Résolution des litiges',
  hashcloudCompanyName: 'Miosga Software UG (haftungsbeschränkt)',
  hashcloudCompanyAddress: 'Chausseestr. 29\n10115 Berlin\nDeutschland',
  hashcloudCompanyContacts: 'E-mail: help@hash.cloud\nTéléphone: +49 (0) 151/22306315',
  hashcloudCompanyRepresentative: 'Tim Miosga',
  hashcloudCompanyRegistryEntry:
    'Enregistré au registre du commerce \nNuméro de registre du commerce: HRB 26713 KI',
  hashcloudCompanyVatId: 'DE297431012',
  hashcloudCompanyContentResponsible: 'Tim Miosga',
  disputeResolutionInfo:
    "La Commission européenne fournit une plateforme pour la résolution en ligne des litiges (ODR): <Link>https://ec.europa.eu/consumers/odr</Link><br />Nous ne sommes ni disposés ni obligés de participer à des procédures de résolution des litiges devant un conseil d'arbitrage des consommateurs.",
  imprintPageTitle: 'Mentions légales',
  termsOfUse: "Conditions d'utilisation",
  privacyPolicy: 'Politique de confidentialité',
  eula: 'CLUF (Contrat de licence utilisateur final)',
  downloadDeutsch: 'Télécharger (allemand)',
  downloadEnglish: 'Télécharger (anglais)',
  infoPageHeroTitle: 'Partagez des fichiers, simplement avec un <em>#hashtag</em>.',
  infoPageHeroFootnote:
    'Avec hashcloud, vous pouvez partager des fichiers rapidement et en toute sécurité. Voici comment cela fonctionne.',
  infoPageSection1Title: 'Créer un hashtag',
  infoPageSection1Content:
    'Trouvez un hashtag que vous pouvez partager avec votre famille, vos amis ou vos collègues.',
  infoPageSection2Title: 'Télécharger des fichiers',
  infoPageSection2Content:
    "Téléchargez les fichiers que vous souhaitez partager avec d'autres. Vous pouvez télécharger des photos, vidéos, présentations, PDF ou fichiers ZIP.",
  infoPageSection3Title: "Accès depuis n'importe où",
  infoPageSection3Content:
    "Toute personne connaissant votre hashtag peut accéder aux fichiers, en télécharger, les supprimer et les modifier via l'application iOS ou web.",
  infoPageFeature1Title: 'Partage rapide de fichiers',
  infoPageFeature1Content:
    "Vous voulez partager des fichiers? Créez simplement un hashtag, téléchargez les fichiers et partagez le hashtag avec d'autres.",
  infoPageFeature2Title: 'Aussi sécurisé que vous le souhaitez',
  infoPageFeature2Content:
    "Décidez de la longueur et de la complexité de votre hashtag pour l'utiliser à des fins privées ou publiques.",
  infoPageFeature3Title: 'Hébergement en Allemagne',
  infoPageFeature3Content:
    'Tous vos fichiers sont stockés sur des serveurs en Allemagne et sont soumis aux réglementations européennes les plus strictes en matière de protection des données.',
  imprint: 'Empreinte',
  legalInformation: 'Informations légales',
  copyright: "Droits d'auteur ©",
  hashcloudDirector: 'Directeur: Tim Miosga',
  hashcloudFooterAddress: 'Chausseestr. 29, 10115 Berlin, Allemagne',
  hashcloudFooterRegistryNumber: 'HRB 26713 KI',
  hashcloudFooterVatId: 'ID TVA: DE297431012',
  eulaShort: 'CLUF',
  fileActionReport: 'Signaler',
  reportHashtagModalTitle: 'Voulez-vous signaler ce hashtag?',
  reportHashtagTooltip: 'Signaler un hashtag',
  yes: 'Oui',
  no: 'Non',
  fileActionsBarDownload: 'Télécharger',
  fileActionsBarDownloadNumFiles: 'Télécharger {{count}} fichiers',
  fileActionsBarDelete: 'Supprimer',
  fileActionsBarDeleteNumFiles_one: 'Supprimer {{count}} fichier',
  fileActionsBarDeleteNumFiles_other: 'Supprimer {{count}} fichiers',
  fileActionsClearSelection: 'Effacer la sélection',
  fileActionsBarNumFilesSelected_one: '{{count}} fichier sélectionné',
  fileActionsBarNumFilesSelected_other: '{{count}} fichiers sélectionnés',
  fileActionsBarNumOwnFilesSelected: '{{count}} téléchargés par vous',
  filesWereSuccessfullyDeleted: 'Les fichiers ont été supprimés avec succès',
  fileActionsBarFilesDeleteModalTitle_one: 'Êtes-vous sûr de vouloir supprimer {{count}} fichier?',
  fileActionsBarFilesDeleteModalTitle_other:
    'Êtes-vous sûr de vouloir supprimer {{count}} fichiers?',
  fileActionDownload: 'Télécharger',
  fileActionOpen: 'Ouvrir',
  fileActionsBarOpen: 'Ouvrir',
  miosgaCompanyName: 'Miosga Software UG (haftungsbeschränkt)',
  miosgaCompanyAddress: 'Chausseestr. 29, 10115 Berlin, Allemagne',
  miosgaCompanyPhone: '+49 (0) 151 22306315',
  miosgaCompanyEmail: 'info@miosga-software.com',
  miosgaOtherCommunications: 'WhatsApp (+49 (0) 151 22306315)',
  miosgaCompanyDirector: 'Tim Alexander Miosga',
  miosgaCompanyRegistry: 'Tribunal de district de Kiel, HRB 26713 KI',
  miosgaCompanyVatId: 'DE297431012',
  miosgaCompanySmallBusiness: 'Petite entreprise conformément au § 19 UStG',
  miosgaCompanyVoluntarySelfControl:
    "Miosga Software UG (haftungsbeschränkt) est membre de l'Autorégulation volontaire des fournisseurs de services multimédia e. V. (FSM).",
  miosgaOfficeAddress: 'Bureau : Beuthstr. 6, 10117 Berlin',
  phone: 'Téléphone',
  email: 'E-mail',
  otherCommunications: 'Autres moyens de communication',
  director: 'Directeur général',
  registry: 'Registre du commerce',
  smallBusiness: 'Petite entreprise',
  voluntarySelfControl: 'Autorégulation volontaire',
  officeAddress: 'Adresse du bureau',
  miosgaFSMOfficeAddress: 'Bureau : Beuthstr. 6, 10117 Berlin',
  miosgaFSMContact:
    'E-mail: <mailLink>office@fsm.de</mailLink> Site web: <webLink>https://www.fsm.de</webLink>',
  miosgaFSMDetails:
    "La FSM est particulièrement dédiée à la protection des mineurs et à la lutte contre les contenus illégaux, préjudiciables aux jeunes et affectant le développement dans les médias en ligne. La nomination d'un responsable de la protection des mineurs est supprimée conformément au § 7 Abs. 2 JMStV. La FSM s'engage à remplir les tâches d'un responsable de la protection des mineurs.",
  mainPageTitle: 'Partagez des fichiers via <highlight>#hashtags</highlight>',
  mainPageSubtitleList:
    '1. Créez un #hashtag<br>2. Partagez-le avec d’autres<br>3. Échangez vos fichiers via celui-ci',
  mainPageSubtitleLinks:
    '<span class="bigger-text">Sur le <boldlink><a href="{{webLink}}" target="_blank">Web</a></boldlink> ou dans l’<boldlink><a href="{{appLink}}" target="_blank">application</a></boldlink>.</span>',
  mainPageFormTitle: 'Quel est votre #hashtag?',
  mainPageFormBtn1Text: 'Afficher les fichiers',
  helpPageTitle: 'Besoin d’aide?',
  helpPageSubtitle:
    'Nous sommes là pour vous aider! Soumettez votre demande, et nous répondrons dès que possible.',
  send: 'Envoyer',
  name: 'Nom',
  optional: 'facultatif',
  inquiry: 'Demande',
  supportRequestSubject: 'Demande de support',
  cookieSettings: 'Paramètres des cookies',
  shareFilesLabel: 'Partagez des fichiers via les #hashtags.',
  welcomeToLabel: 'Bienvenue sur ',
  welcome: '#bienvenue',
  form: {
    hashtag_files_start: 'Veuillez entrer votre ',
    hashtag_files_middle: ' pour voir tous les fichiers téléchargés ',
    hashtag_files_end: '.',
  },
  words: {
    hashtag: 'hashtag',
    files: 'fichiers',
  },
  profileHashtagsLayerTitle: 'Hashtags',
  profileHashtagsPanelText: 'Mes hashtags',
  profileHashtagsEdit: 'Modifier',
  profileHashtagsDoneEditing: 'Terminé',
  profileHashtagsRemoveTooltip: 'Supprimer #{{hashtagName}} de « Mes hashtags »',
  profileHashtagsEmpty: 'Pas encore de hashtags ici',
  profileHashtagsOwnHashtag: 'Créé par vous',
  joinHashtag: 'Rejoindre un hashtag',
  joinHashtagModalTitle: 'Veuillez entrer un hashtag à rejoindre',
  joinHashtagDoesntExist: 'Le hashtag indiqué n’a pas encore été créé',
};

export default fr;
