import { PropsWithChildren } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import Modal from 'components/Modal';
import Button from 'components/Button';

import useGlobalState from 'hooks/useGlobalState';

import { IHashtagData, Nullable } from 'types/interfaces';

import { ReactComponent as IconFlag } from 'images/icons/flag3.svg';

import styles from './HashtagReport.module.scss';

interface IProps {
  hashtagData: Nullable<IHashtagData>;
}

const HashtagReport: React.FC<PropsWithChildren<IProps>> = props => {
  const { hashtagData } = props;

  const [globalState] = useGlobalState();
  const { profile } = globalState;

  const { t } = useTranslation();
  const [isModalOpen, toggleModalState] = useToggle(false);

  if (!hashtagData) {
    return <></>;
  }

  const handleReportClick = () => {
    const subject = `Report of Inappropriate Hashtag -  "${hashtagData?.name}" (ID: ${hashtagData?.uid})`;
    const today = new Date();

    const formattedDate = today.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const body = `
Dear Support Team,

I am writing to report an issue with a hashtag in the hash.cloud storage. Please find the details below:

Hashtag Name: "${hashtagData.name}"
Hashtag ID: ${hashtagData.uid}
Reported By: ${profile?.name.customUsername || '[Your Name]'}
User ID/Email: ${profile?.uid || '[Your User ID or Email]'}
Date of Report: ${formattedDate}

Description of the Issue:
[Provide a brief description of the issue with the hashtag. For example, it may contain inappropriate content, malware, or it might be a case of unauthorized sharing.]

Additional Information:
[Include any additional information that might be relevant to the issue. For example, links to the hashtag, screenshots, or steps to reproduce the problem.]

Thank you for your prompt attention to this matter.

Best regards,
${profile?.name.customUsername || '[Your Name]'}
[Your Contact Information]`;

    const url = `mailto:report@hash.cloud?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body.trim())}`;

    window.location.assign(url);

    toggleModalState(false);
  };

  return (
    <>
      <div
        className={styles.iconContainer}
        onClick={toggleModalState}
        title={t('reportHashtagTooltip')}
      >
        <IconFlag className={styles.icon} />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModalState}
        className={styles.modal}
        contentClassName={styles.modalContent}
        showCloseButton
      >
        <h2 className={styles.title}>{t('reportHashtagModalTitle')}</h2>

        <div className={styles.btnContainer}>
          <Button
            type="secondary"
            className={cn(styles.btn, styles.cancelBtn)}
            onClick={toggleModalState}
          >
            {t('no')}
          </Button>
          <Button
            type="primary"
            className={cn(styles.btn, styles.submitBtn)}
            onClick={handleReportClick}
          >
            {t('yes')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default HashtagReport;
