import { SelectionEvent } from '@viselect/react';
import { useState } from 'react';

const useDragSelect = () => {
  const [selected, setSelected] = useState<Set<string>>(() => new Set());

  const extractIds = (els: Element[]): string[] =>
    els
      .map(v => v.getAttribute('data-key'))
      .filter(Boolean)
      .map(String);

  const onStart = ({ event, selection }: SelectionEvent) => {
    if (!event?.ctrlKey && !event?.metaKey) {
      selection.clearSelection();
      setSelected(() => new Set());
    }
  };

  const onMove = ({
    store: {
      changed: { added, removed },
    },
  }: SelectionEvent) => {
    setSelected(prev => {
      const next = new Set(prev);

      extractIds(added).forEach(id => next.add(id));
      extractIds(removed).forEach(id => next.delete(id));

      return next;
    });
  };

  const onBeforeStart = ({ event }: SelectionEvent) => {
    if (!event || !event.target) return;

    const isNonSelectableElementItself = (event.target as HTMLElement)?.matches('.nonSelectable');
    const isChildOfNonSelectableElement = (event.target as HTMLElement)?.closest('.nonSelectable');

    return !isNonSelectableElementItself && !isChildOfNonSelectableElement;
  };

  return { selected, onStart, onMove, onBeforeStart };
};

export default useDragSelect;
