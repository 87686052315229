import cn from 'classnames';
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { ReactNode } from 'react';

import InputLabel from 'components/InputLabel';

import { IInputProps } from 'types/interfaces';

import styles from './Textarea.module.scss';

interface IProps extends IInputProps<string> {
  className?: string;
  inputClassName?: string;
  labelClassName?: string;
  placeholder?: string;
  error?: ReactNode;
  required?: boolean;
  hasError?: any;
  maxLength?: number;
}

const Textarea = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: UseControllerProps<TFieldValues, TName> & IProps
) => {
  const {
    control,
    label,
    name,
    inputClassName,
    className,
    labelClassName,
    placeholder,
    maxLength,
    error,
    hasError,
  } = props;

  const { field } = useController({ name, control });
  const { value } = field;

  const charactersUsed = value?.length || 0;

  return (
    <div
      className={cn(
        styles.container,
        className,

        { [styles.hasError]: Boolean(error || hasError) }
      )}
    >
      <InputLabel className={cn(styles.label, labelClassName)}>
        {label}

        {maxLength && (
          <span className={styles.labelRight}>
            {charactersUsed} / {maxLength}
          </span>
        )}
      </InputLabel>

      <div className={cn(styles.textarea, inputClassName)}>
        <textarea
          className={styles.textareaTextarea}
          spellCheck="false"
          placeholder={placeholder}
          maxLength={maxLength}
          {...field}
        />
      </div>

      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Textarea;
