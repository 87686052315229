import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useLifecycles } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ReactModal from 'react-modal';

import Button from 'components/Button';

import PasswordInput from 'components/PasswordInput';

import styles from './PasswordProtectionModal.module.scss';

interface IProps {
  isOpen: boolean;
  checkPassword: (password: string) => Promise<boolean>;
}

const PasswordProtectionModal = (props: IProps) => {
  const { isOpen, checkPassword } = props;

  const [password, setPassword] = useState('');
  const [hasValidationError, setValidationError] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setValidationError(false);

    setPassword(value);
  };

  const handleBtnClick = async () => {
    const isPasswordCorrect = await checkPassword(password);

    if (!isPasswordCorrect) {
      setValidationError(true);
    }
  };

  const handleEnterKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key !== 'Enter') return;

    submitBtnRef.current?.click();
  }, []);

  useLifecycles(
    () => {
      document.addEventListener('keypress', handleEnterKeyPress);
    },
    () => {
      document.removeEventListener('keypress', handleEnterKeyPress);
    }
  );
  const { t } = useTranslation();
  return (
    <>
      <ReactModal
        isOpen={isOpen}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        htmlOpenClassName="overflowHidden"
        className={styles.modal}
        portalClassName="hashare-portal"
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContent}>
          <h2 className={styles.title}>{t('passwordEnterTitle')}</h2>
          <h3 className={styles.subtitle}>{t('passwordEnterPromt')}</h3>

          <PasswordInput
            error={hasValidationError ? 'Password is invalid' : undefined}
            name="password"
            label={t('password')}
            required
            className={styles.input}
            inputClassName={cn({ [styles.inputContainerWithError]: hasValidationError })}
            autoFocus
            onChange={handlePasswordChange}
            value={password}
            autoComplete="new-password"
          ></PasswordInput>

          <div className={styles.btnContainer}>
            <Button
              ref={submitBtnRef}
              htmlType="submit"
              className={styles.btn}
              type="primary"
              onClick={handleBtnClick}
              disabled={!password || hasValidationError}
            >
              {t('submit')}
            </Button>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default PasswordProtectionModal;
